import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useDisclosure, Text, Icon, useToast } from '@chakra-ui/react'
import { useRef } from 'react'
import { TbUsers } from 'react-icons/tb'
import api from 'services/api';
import { useAuth } from 'services/auth/AuthProvider';
import { db } from 'services/db';

const MentorRequestConfirm = (props: { mentorid: number, setstatus: any }) => {

  const { mentorid } = props;
  const { muuid } = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const { user, allowMentor } = useAuth();
  const toast = useToast();
  const mentoast = 'mentoast';

  const requestmentor = async () => {

    await api.post('/mentor/request', { mid: mentorid, last_uuid: muuid }).then(async (res) => {

      if (res.data.message) {
        switch (res.data.message) {
          case 'subs': toast({ title: 'You have a FREE account ...', description: "You need to upgrade your subscription to access mentorship", status: 'info', position: 'top', duration: 4000, isClosable: false }); break;
          case 'count': toast({ title: 'Maximum mentors reached ...', description: "You will need to cancel an existing mentorship (pending or active) or upgrade your plan to 'PRO' to request more mentorships", status: 'info', position: 'top', duration: 4000, isClosable: false }); break;
          case 'pending':
          case 'requested':
          case 'active': toast({ title: 'Mentorship is already ' + res.data.message + ' with this mentor ...', description: "", status: 'info', position: 'top', duration: 3000, isClosable: false }); break;
          case 'self': toast({ title: 'You cannot request yourself ...', description: "", status: 'warning', position: 'top', duration: 3000, isClosable: false }); break;
          case 'mentor': toast({ title: 'Mentors cannot request other mentors ...', description: "", status: 'warning', position: 'top', duration: 3000, isClosable: false }); break;
          case 'none': toast({ title: 'This person is no longer a mentor ...', description: "", status: 'warning', position: 'top', duration: 3000, isClosable: false }); break;
          case 'failed': toast({ title: 'Request failed ...', description: "Please try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: false }); break;
        }
        props.setstatus('');
      }
      else {
        res.data.sync_due = false;
        delete res.data.last_uuid;
        await db.mentors.put(res.data, res.data.id).then(() => {
          toast({ title: 'Mentorship request sent ...', description: "We have emailed the mentor your request.", status: 'success', position: 'top', duration: 3000, isClosable: false });
        })
        props.setstatus('requested');
      }

    }).catch(async (err) => {
      props.setstatus('');
      toast({ title: 'Unable to reach server ...', description: "Please check your internet connection and/or try again in a few minutes", status: 'error', position: 'top', duration: 4000, isClosable: false });
    })

    onClose();
  }

  const openRequest = async () => {
    await allowMentor().then(async (allow: boolean) => {
      if (allow) {
        onOpen();
      }
      else {
        if (!toast.isActive(mentoast)) {
          let str = '';
          switch (user.role) {
            case 'FREE':
              str = "\nYou need to be on a NOVICE or PRO plan to access mentorships.\n\nGo to the subscription page to upgrade."
              toast({ id: mentoast, title: 'Upgrade needed ...', description: <Text whiteSpace="pre-line">{str}</Text>, status: 'info', position: 'top', duration: 5000, isClosable: true })
              break;
            case 'NOVICE':
              str = "\nYou can have 1 mentor on the NOVICE plan.\n\nUpgrade your subscription to PRO to request more mentors."
              toast({ id: mentoast, title: 'Maximum mentors reached ...', description: <Text whiteSpace="pre-line">{str}</Text>, status: 'error', position: 'top', duration: 5000, isClosable: true })
              break;
            case 'PRO':
              str = "\nYou can have 10 mentors on the PRO plan.\n\nYou will need to cancel an existing mentorship to request a new one."
              toast({ id: mentoast, title: 'Maximum mentors reached ...', description: <Text whiteSpace="pre-line">{str}</Text>, status: 'error', position: 'top', duration: 5000, isClosable: true })
              break;
          }

        }
      }
    });
  }

  return (
    <>
      <Button variant={'outline'} colorScheme={'teal'} onClick={openRequest} ><Icon as={TbUsers} boxSize={5} me='10px' />
        Request mentorship
      </Button>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Request mentorship
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text mb='20px' color={'red.500'} fontWeight={'700'}>NOTE: This will share your email with the requested mentor so they can contact you if necessary.</Text>
              <Text fontWeight={'700'}>Request mentorship and share your email?</Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='blue' onClick={requestmentor} ml={3}>
                Yes, request!
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default MentorRequestConfirm