import { Account, AccountGroup, AccountTableRow, Trade } from "views/trader/trades/variables/types";

export function getAccountRows(accounts: Account[], groups: AccountGroup[], trades: Trade[]): Promise<AccountTableRow[]> {

    return new Promise(async (resolve, reject) => {

        let rows: AccountTableRow[] = [];

        let order = 1;

        for (let a of accounts) {

            let equity = a.init_balance;
            let group = groups.find((g) => g.groupid === a.groupid);

            let atrades = trades.filter((t) => t.accountid === a.accountid);

            for (let tr of atrades) {
                let ex = (tr.currency !== a.currency ? tr.exchange : 1);
                let nett = (tr.nett / ex);
                equity += nett;
            }


            for (let b of a.adjustments) {
                equity += b.amount;
            }

            let row: AccountTableRow = {
                order: order,
                accountid: a.accountid,
                groupname: group.groupname,
                accountname: a.accname,
                equity: equity,
                change: equity - a.init_balance,
                currency: a.currency,
                trades: atrades.length,
                percentage: ((equity - a.init_balance) / a.init_balance) * 100
            }

            order++;

            rows.push(row);

        }
        resolve(rows);
    })

}