import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Icon, Input, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import Card from 'components/card/Card'
import React from 'react'
import { MdFullscreen } from 'react-icons/md'
import { profitWinChartData } from 'views/trader/trades/variables/types'
import ProfitFactorWinChart from '../charts/ProfitFactorWinChart'


const ProfitFactorWinModal = (props: { profitWinData: profitWinChartData, hidden: boolean }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    const drawerBg = useColorModeValue('white', 'navy.800');

    return (
        <>
            <Button variant='outline' onClick={onOpen} borderRadius={'5px'} size={'sm'} ms='5px' hidden={props.hidden}>
                <Icon as={MdFullscreen} boxSize={5} />
            </Button>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
                size={'full'}
            >
                <DrawerOverlay />
                <DrawerContent bg={drawerBg} h='100%'>
                    <DrawerCloseButton />
                    <DrawerHeader>Profit Factor / Win%</DrawerHeader>

                    <DrawerBody>
                        <Card h={'100%'}>
                            <ProfitFactorWinChart profitWinData={props.profitWinData} hidden={false} showtoolbar={true} height={'95%'} />
                        </Card>

                    </DrawerBody>

                </DrawerContent>
            </Drawer>
        </>
    )
}

export default ProfitFactorWinModal