

// Chakra Imports
import {
  Flex,
  useColorModeValue
} from '@chakra-ui/react';
// Custom Components
import { SidebarResponsive } from 'components/sidebar/Sidebar';
// Assets

import AccountDrawer from 'views/trader/components/AccountDrawer';
import routes from 'routes';
import StatPeriod from 'views/trader/components/inputs/StatPeriod';
import { useEffect, useState } from 'react';
import ThemeToggler from './ThemeToggler';
import { useAuth } from 'services/auth/AuthProvider';
import MenteeInvite from 'views/trader/mentor/components/MenteeInvite';
import MessageDrawer from 'views/trader/mentor/components/messages/MessageDrawer';


export default function HeaderLinks(props: { secondary: boolean;[x: string]: any; }) {

  const { user, IsMentored, HasMentees } = useAuth();

  const { secondary, theme, setTheme } = props;

  const [path, setPath] = useState(window.location.pathname);

  // Chakra Color Mode
  let menuBg = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  useEffect(() => {
    let p = window.location.pathname.split('/');
    setPath(p[2])
  }, [window.location.pathname])

  return (
    <Flex w={{ sm: '100%', md: 'auto' }} alignItems="center" flexDirection="row" bg={menuBg} flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'} p="10px" borderRadius="50px" boxShadow={shadow} >

      <SidebarResponsive routes={routes} />

      {user && <AccountDrawer
        mini={props.mini}
        setMini={props.setMini}
        theme={theme} setTheme={setTheme}
        />}
      <ThemeToggler />
      {/*<Configurator mini={props.mini} setMini={props.setMini} theme={theme} setTheme={setTheme} />*/}
      <StatPeriod hidden={path !== 'dashboard' && path !== 'trades'}></StatPeriod>
      <Flex hidden={user.role !== 'MENTOR' || path !== 'mentorship'}>
        <MenteeInvite />
      </Flex>
      {(HasMentees || IsMentored) && <Flex>
        <MessageDrawer />
      </Flex>}
    </Flex>
  );
}
