import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Icon, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import Card from 'components/card/Card'
import React, { useRef } from 'react'
import { MdFullscreen } from 'react-icons/md'
import { equityChartData } from 'views/trader/trades/variables/types'
import EquityChart from '../charts/EquityChart'

const EquityModal = (props: { equityChartData: equityChartData, hidden: boolean }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    const drawerBg = useColorModeValue('white', 'navy.800');
    const cardRef = useRef(null);

    return (
        <>
            <Button variant='outline' onClick={onOpen} borderRadius={'5px'} size={'sm'} ms='5px' hidden={props.hidden}>
                <Icon as={MdFullscreen} boxSize={5} />
            </Button>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
                size={'full'}
            >
                <DrawerOverlay />
                <DrawerContent bg={drawerBg} h='100%'>
                    <DrawerCloseButton />
                    <DrawerHeader>Equity Curve</DrawerHeader>
                    <DrawerBody>
                        <Card h={'100%'} ref={cardRef}>
                            <EquityChart equityChartData={props.equityChartData} hidden={false} showtoolbar={true} height={'95%'}/>
                        </Card>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default EquityModal