import { Box, Flex, ScaleFade, Text, useColorModeValue } from '@chakra-ui/react';
import { ApexOptions } from 'apexcharts';
import Card from 'components/card/Card';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { equityChartData } from 'views/trader/trades/variables/types';
import reseticon from 'assets/icons/reseticon.png';
import downicon from 'assets/icons/downicon.png';
import dayjs from 'dayjs';

const EquityChart = (props: { equityChartData: equityChartData, hidden: boolean, showtoolbar: boolean, height: string }) => {

    
    const [nochartdata, setNoChartData] = useState(false);
    const textColorSecondary = useColorModeValue('secondaryGray.700', 'secondaryGray.500');

    useEffect(() => {
        updateChartData();
    }, [props.equityChartData])

    const updateChartData = () => {

        if (props.equityChartData.series[0].data.length) {
            setNoChartData(false);
            options.annotations.yaxis[0].y = props.equityChartData.initBalance;
            options.fill.gradient.colorStops = [[{ offset: 0, color: props.equityChartData.color, opacity: (props.equityChartData.equityChange > 0 ? 1 : 0.25) }, { offset: 100, color: props.equityChartData.color, opacity: (props.equityChartData.equityChange > 0 ? 0.25 : 1) }]];
            options.xaxis.categories = props.equityChartData.categories;
            setVariables({ options: options, series: props.equityChartData.series });
        }
        else {
            setNoChartData(true);
        }
   
    }

    const options: ApexOptions = {
        chart: {
            toolbar: {
                show: props.showtoolbar,
                offsetY: -20,
                tools: {
                    download: false, //'<img src=' + downicon + ' width="40">',
                    selection: false,
                    zoom: true,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: '<img src=' + reseticon + ' width="40">',
                    customIcons: [{
                        icon: '<img src=' + downicon + ' width="40">',
                        index: 0,
                        title: 'download',
                        click: function (chart, options, e) {
                            chart.dataURI().then((imgURI: any) => {
                                const a = document.createElement('a')
                                a.download = 'equity_'+dayjs().format("YYYY-MM-DD")+'.png'
                                a.href = imgURI.imgURI;
                                a.click()
                            })
                        }
                    }]
                }
            }
        },
        annotations: {
            yaxis: [{
                y: 0,
                borderColor: '#848287',
                label: {
                    borderColor: '#848287',
                    style: {
                        color: '#ffffff',
                        background: '#848287',
                        fontWeight: 700,
                    },
                    text: 'INITIAL',
                    textAnchor: 'start',
                    offsetY: -5,
                    position: 'start'
                }
            }]
        },
        markers: {
            size: 0,
            colors: '#868CFF',
            strokeColors: 'white',
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            shape: 'circle',
            offsetX: 0,
            offsetY: 0,
            showNullDataPoints: true
        },
        tooltip: {
            theme: 'dark',
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            labels: {
                show: false,
                formatter: (value) => {
                    return dayjs(value).format('DD MMM YYYY');
                }
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            }
        },
        yaxis: {
            show: true,
            opposite: true,
            labels: {
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 160,
                style: {
                    colors: ['#848287'],
                    fontSize: '11px',
                    fontWeight: 700,
                },
                offsetX: 0,
                offsetY: 0,
                rotate: 0,
                formatter: (value) => { return value.toLocaleString() },
            },
        },
        legend: {
            show: false
        },
        grid: {
            show: false,
        },
        fill: {
            type: 'gradient',
            gradient: {
                type: 'vertical',
                shadeIntensity: 0.1,
                opacityFrom: 0.3,
                opacityTo: 0.9,
                colorStops: []
            }
        }
    };

    const [variables, setVariables] = useState({ options: options, series: [{ name: "", data: [], color: "", type: "area" }] });

    return (
        <>
            <Box mt='20px' minH={{base:'350px', md:'550px'}} h='100%' w='100%' p='0px' hidden={props.hidden}>
                <Flex hidden={!nochartdata} justify={'center'} align={'center'} >
                    <ScaleFade initialScale={0.5} in={true} transition={{ exit: { delay: 0.5 }, enter: { duration: 1 } }}>
                        <Box p='10px' color={textColorSecondary} >
                            <Text color={textColorSecondary} fontSize='md' mt='20px' mb='20px' ms='20px'>No data to display</Text>
                        </Box>
                    </ScaleFade>
                </Flex>
                <Chart
                    hidden={nochartdata}
                    options={variables.options}
                    type="area" // added above in variables
                    width="100%"
                    height={props.height}
                    series={variables.series}
                />
            </Box>
        </>
    );
};

export default EquityChart;