import { useDisclosure, Button, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, DrawerFooter, Icon, useColorModeValue, Grid, GridItem, useToast, Text } from '@chakra-ui/react'
import { useState } from 'react';
import { CgUserList } from "react-icons/cg";
import { MentorProfile } from 'views/trader/trades/variables/types';
import MentorBannerView from './profile/MentorBannerView';
import api from 'services/api';
import { db } from 'services/db';

const MentorDirectoryDrawer = () => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const bgcolor = useColorModeValue('white', 'navy.700');
    const btncol = useColorModeValue('teal.400', 'teal.300');
    const [mentors, setMentors] = useState<MentorProfile[]>([]);
    const [fetching, setFetching] = useState(false);
    const toast = useToast();

    const openModal = async () => {
        setFetching(true);
        await api.post('/mentor/getprofiles').then(async (res) => {
            if (res.data !== false) {
                if (res.data.length) {
                    for (let mp of res.data) {
                        await db.mentors.where({ mentorname: mp.username }).toArray().then((m) => {
                            mp.status = (m.length ? m[0].status : '');
                        })
                    }
                    setMentors(res.data);
                    setFetching(false);
                    onOpen();
                }
                else {
                    setFetching(false);
                    toast({ title: 'No PUBLIC mentor profiles ...', description: <Text whiteSpace="pre-line">{"\nMentors are keeping their profiles private for now unfortunately.\n\nIf you've arranged a mentorship privately, give them the email address you registered with TradeJorno so that they can send you an invite.\n\nRemind your mentor to share their profile on the platform so that other traders can find them too.\n\n"}</Text>, status: 'info', position: 'top', duration: null, isClosable: true })
                }
            }
            else {
                setFetching(false);
                toast({ title: 'Unable to fetch mentor profiles ...', description: 'Please try again in a few minutes', status: 'error', position: 'top', duration: 3000, isClosable: true })
            }
        }).catch((e)=>{
            setFetching(false);
            toast({ title: 'Unable to fetch mentor profiles ...', description: 'Please try again in a few minutes', status: 'error', position: 'top', duration: 3000, isClosable: true })
        })
    }

    const statstring = (n: number) => {
        if (n > 1000) {
            return (n / 1000).toFixed(1) + "k";
        }
        else {
            return n.toString();
        }
    }

    return (
        <>
            <Button variant={'outline'} bg={bgcolor} borderColor={btncol} color={btncol} onClick={openModal} borderWidth={'2px'} zIndex={1} isLoading={fetching} loadingText={'Fetching'}>
                <Icon boxSize={5} as={CgUserList} me='10px' />Directory
            </Button>
            <Drawer
                isOpen={isOpen}
                size={'full'}
                placement='top'
                onClose={onClose}
            >
                <DrawerOverlay />
                <DrawerContent bg={bgcolor}>
                    <DrawerCloseButton />
                    <DrawerHeader>Mentor Directory</DrawerHeader>
                    <DrawerBody>
                        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)', xl: 'repeat(4, 1fr)' }} gap={5} mt='10px' mb='10px'>
                            {mentors.map((m, index) => (
                                <GridItem w='100%'>
                                    <MentorBannerView
                                        gridArea='1 / 1 / 2 / 2'
                                        avatar={m.avatar}
                                        name={m.name}
                                        username={m.username}
                                        description={m.description}
                                        strategies={statstring(m.strategies)}
                                        s_show={m.s_show}
                                        trades={statstring(m.trades)}
                                        t_show={m.t_show}
                                        journals={statstring(m.journals)}
                                        j_show={m.j_show}
                                        x_url={m.x_handle}
                                        y_url={m.yt_handle}
                                        i_url={m.ig_handle}
                                        w_url={m.weburl}
                                        mid={m.userid}
                                        status={m.status}
                                    />
                                </GridItem>
                            ))}
                        </Grid>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant='outline' mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default MentorDirectoryDrawer