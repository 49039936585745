import { Instrument } from "../variables/types";
import { useAuth } from "services/auth/AuthProvider";


const useNewInstrument = () => {

	const { user } = useAuth();//useContext(AuthContext);

	const newInst: Instrument = {
		id: 0,
		userid: (user === null ? 0 : user.id),
		ticker: "",
		description: "",
		category: "",
		subcategory: "",
		currency: "",
		pointvalue: 0,
		ticksize: 0,
		timezone: "",
		relevance: 0,
		defaultstop: 0,
		commission: [],
		sync_due: false
	};
	return newInst;
};

export default useNewInstrument;