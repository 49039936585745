import {
    useDisclosure,
    Button,
    Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalOverlay,
    ModalFooter, Flex, Spacer, Text, OrderedList, ListItem,
    useColorModeValue,
    Image,
    Heading,
    Stack,
    Grid,
    GridItem
} from "@chakra-ui/react";

import {
    MdAdd
} from 'react-icons/md';

import { useState, useRef, ChangeEvent } from "react";
import FileDropZone from "../fields/FileDropZone";
import ninja from 'assets/img/jorno/platforms/p_ninja.png';
import tvate from 'assets/img/jorno/platforms/p_tvate.png';
import tviewdark from 'assets/img/jorno/platforms/p_tviewdark.png';
import tviewlight from 'assets/img/jorno/platforms/p_tviewlight.png';
import wcdark from 'assets/img/jorno/platforms/wealthcharts_dark.png';
import wclight from 'assets/img/jorno/platforms/wealthcharts_light.png';
import BrokerCard from "./components/BrokerCard";

const UploadBrokerModal = (props: { clearimport: any, setfile: any, processfile: any, setPlatform: any, hidden: boolean }) => {

    const platarray = [
        {
            id: 1,
            text: "Ninjatrader",
            image: ninja,
            height: '20px',
            platform: 'ninja',
        },
        {
            id: 2,
            text: "Tradovate",
            image: tvate,
            height: '30px',
            platform: 'tvate',
        },
        {
            id: 3,
            text: "TradingView",
            image: useColorModeValue(tviewdark, tviewlight),
            height: '20px',
            platform: 'tview',
        }
        /*,
        {
            id: 4,
            text: "WealthCharts",
            image: useColorModeValue(wclight, wcdark),
            height: '30px',
            platform: 'wcharts',
        }*/
    ]

    const bdcolor = useColorModeValue('gray.200', 'gray.600');
    const bgcolor = useColorModeValue('transparent', 'transparent');
    const bgselectcolor = useColorModeValue('gray.200', 'navy.800');

    const textColor = useColorModeValue('gray.600', 'gray.300');


    const { isOpen, onOpen, onClose } = useDisclosure();

    const [broker, setBroker] = useState('');
    //const [metaTrader, setMetaTrader] = useState('');
    const [fileType, setFileType] = useState('');

    const [filename, setFilename] = useState('');
    const chooser = useRef(null);

    const brokerChange = (value: string) => {
        setBroker(value);
        //setMetaTrader('');
        props.setPlatform(value);
        /*switch (value) {
            case 'metaTrader':
                setFileType('.html, .htm');
                break;
            case 'ninja':
                setFileType('.xlsx');
                break;
            default:
                setFileType('.csv');
        }*/
    }
    /*const metaTraderChange = (value: string) => {
        //setMetaTrader(value);
    }*/

    const handleClick = () => {
        chooser.current.click();
    }

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            props.setfile(e.target.files[0]);
            setFilename(e.target.files[0].name);
        }
        else {
            clearFile();
        }
    };
    const clearFile = () => {
        props.setfile(null);
        props.setPlatform('');
        setFilename('');
    }

    const brokerModalOpen = () => {
        props.clearimport();
        onOpen();
    }

    const modalClosed = () => {
        setFilename('');
        setBroker('');
        //setMetaTrader('');
    }
    const updateFileChoice = (file: any) => {
        if (file) {
            props.setfile(file);
            setFilename(file.name);
        }
        else {
            clearFile();
        }
    }

    return (
        <>
            <Flex align='center'>
                <Button hidden={props.hidden} mb='20px' size='sm' borderRadius='5px' rightIcon={<MdAdd />} colorScheme='blue' variant='outline' onClick={() => brokerModalOpen()}>PLATFORM Statement</Button>
            </Flex>

            <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'full', lg: "2xl" }} onCloseComplete={modalClosed} onOverlayClick={clearFile} >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Platform Upload</ModalHeader>
                    <ModalCloseButton onClick={() => clearFile()} />
                    <ModalBody>
                        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={5} mb='30px'>
                            {platarray.map((arr) => (
                                <GridItem>
                                    <BrokerCard broker={broker} arr={arr} brokerchange={brokerChange} />
                                </GridItem>

                            ))}
                        </Grid>
                        
                        <Flex direction='column' hidden={broker !== 'tvate'}>
                            <OrderedList spacing={3}>
                                <ListItem>On <Text as='b'>TRADOVATE</Text>, go to the <Text as='b'><Text as='u'>Account Reports</Text></Text> page.</ListItem>
                                <ListItem>Select the <Text as='b'><Text as='u'>Orders</Text></Text> tab.</ListItem>
                                <ListItem>Select your Date range.</ListItem>
                                <ListItem>Click the <Text as='b'><Text as='u'>Download CSV</Text></Text> button.</ListItem>
                                <ListItem>Choose the downloaded file below and click <Text as='b'>Upload</Text>.</ListItem>
                            </OrderedList>
                        </Flex>
                        <Flex direction='column' hidden={broker !== 'tview'}>
                            <OrderedList spacing={3}>
                                <ListItem>On <Text as='b'>TRADINGVIEW</Text>, open/expand the <Text as='b'><Text as='u'>Paper Trading</Text></Text> tab in the brokers window.</ListItem>
                                <ListItem>Open the  <Text as='b'><Text as='u'>History</Text></Text> tab and select the <Text as='b'><Text as='u'>Filled</Text></Text> tab.</ListItem>
                                <ListItem>Click the <Text as='b'><Text as='u'>Paper Trading</Text></Text> dropdown and select <Text as='b'><Text as='u'>Export Data ...</Text></Text></ListItem>
                                <ListItem>Select <Text as='b'><Text as='u'>History</Text></Text> and click <Text as='b'><Text as='u'>Export</Text></Text>.</ListItem>
                                <ListItem>Choose the downloaded file below and click <Text as='b'>Upload</Text>.</ListItem>
                            </OrderedList>
                        </Flex>

                        <Flex direction='column' hidden={broker !== 'ninja'}>
                            <OrderedList spacing={3}>
                                <ListItem>On <Text as='b'>NINJATRADER</Text>, select <Text as='b'><Text as='u'>New</Text></Text> {' -> '} <Text as='b'><Text as='u'>Trade performance</Text></Text>.</ListItem>
                                <ListItem>On the top left, select <Text as='b'><Text as='u'>Display</Text></Text> {' -> '} <Text as='b'><Text as='u'>Executions</Text></Text>.</ListItem>
                                <ListItem>On the top right, select date range and click <Text as='b'><Text as='u'>Generate</Text></Text>.</ListItem>
                                <ListItem>Right click on the window and select <Text as='b'><Text as='u'>Export</Text></Text>.</ListItem>
                                <ListItem>Save the file in <Text as='b'><Text as='u'>.XLSX</Text></Text> {'('}excel{')'} format.</ListItem>
                                <ListItem>Choose the downloaded file below and click <Text as='b'>Upload</Text>.</ListItem>
                            </OrderedList>
                        </Flex>
                        {/*<Flex direction='column' hidden={broker !== 'wcharts'}>
                            <OrderedList spacing={3}>
                                <ListItem>On <Text as='b'>WEALTHCHARTS</Text>, open a <Text as='b'><Text as='u'>Broker Portfolio</Text></Text> window.</ListItem>
                                <ListItem>Select the <Text as='b'><Text as='u'>HISTORY</Text></Text> tab.</ListItem>
                                <ListItem>Click the <Text as='b'><Text as='u'>settings</Text></Text> gear button.</ListItem>
                                <ListItem>Ensure <Text as='b'><Text as='u'>ALL</Text></Text> columns are selected</ListItem>
                                <ListItem>Click the <Text as='b'><Text as='u'>Export to CSV</Text></Text> button.</ListItem>
                                <ListItem>Choose the downloaded file below and click <Text as='b'>Upload</Text>.</ListItem>
                            </OrderedList>
                        </Flex>
                        <Flex direction='column' hidden={broker !== 'ib'}>
                            <OrderedList spacing={3}>
                                <ListItem>Login to the client portal.</ListItem>
                                <ListItem>Select <Text as='b'><Text as='u'>Performance and Reports</Text></Text> {' -> '} <Text as='b'><Text as='u'>Flex Queries</Text></Text></ListItem>
                                <ListItem>Click + to add a new <Text as='b'><Text as='u'>Activity Query</Text></Text>.</ListItem>
                                <ListItem>Under <Text as='b'><Text as='u'>Sections</Text></Text> select <Text as='b'><Text as='u'>Trades</Text></Text>.</ListItem>
                                <ListItem>Under <Text as='b'><Text as='u'>Options</Text></Text> select <Text as='b'><Text as='u'>Execution</Text></Text>.</ListItem>
                                <ListItem>Then <Text as='b'><Text as='u'>Select All</Text></Text> fields and click <Text as='b'><Text as='u'>Save</Text></Text>.</ListItem>
                                <ListItem>Under <Text as='b'><Text as='u'>Delivery configuration</Text></Text> select:
                                    <UnorderedList spacing={2}>
                                        <ListItem>Format: CSV</ListItem>
                                        <ListItem>Period: Month to Date</ListItem>
                                    </UnorderedList>
                                </ListItem>
                                <ListItem>Leave the rest as default and click <Text as='b'><Text as='u'>Continue</Text></Text>.</ListItem>
                                <ListItem>On the next page click <Text as='b'><Text as='u'>Create</Text></Text>.</ListItem>
                                <ListItem>Now run the query and save the <Text as='b'><Text as='u'>.CSV</Text></Text> file.</ListItem>
                                <ListItem>Choose the downloaded file below and click <Text as='b'>Upload</Text>.</ListItem>
                            </OrderedList>
                        </Flex>*/}
                        {/*<Flex direction='column' hidden={broker !== 'optimus'}>
                            <OrderedList spacing={3}>
                                <ListItem>Click the OPTIMUS<Text as='b'><Text as='i'>FLOW</Text></Text> banner {'('}top left{')'} and select <Text as='b'><Text as='u'>Orders History</Text></Text>.</ListItem>
                                <ListItem>On the left of the Orders History tab title click the <Icon as={MdMenu} boxSize={5} ms='8px' me='8px' mb='0px' /> menu icon.</ListItem>
                                <ListItem>Select <Text as='b'><Text as='u'>Export Data</Text></Text>.</ListItem>
                                <ListItem>In the export data dialog select <Text as='b'><Text as='u'>Check all columns</Text></Text>.</ListItem>
                                <ListItem>Click <Text as='b'><Text as='u'>Export Data</Text></Text> and save locally.</ListItem>
                                <ListItem>Choose the downloaded file below and click <Text as='b'>Upload</Text>.</ListItem>
                            </OrderedList>
                        </Flex>*/}
                        {/*<Flex direction='column' hidden={metaTrader !== 'metaTrader4' || broker !== 'metaTrader'}>
                            <OrderedList spacing={3}>
                                <ListItem>Open the <Text as='b'><Text as='u'>Terminal</Text></Text> and select the  <Text as='b'><Text as='u'>Account history</Text></Text> tab.</ListItem>
                                <ListItem>Right click on the table and set your desired period.</ListItem>
                                <ListItem>Right click on the table and select <Text as='b'><Text as='u'>Save as report</Text></Text> to export.</ListItem>
                                <ListItem>Choose the downloaded .HTM or .HTML file below and click <Text as='b'>Upload</Text>.</ListItem>
                            </OrderedList>
                        </Flex>
                        <Flex direction='column' hidden={metaTrader !== 'metaTrader5' || broker !== 'metaTrader'}>
                            <OrderedList spacing={3}>
                                <ListItem>Open the <Text as='b'><Text as='u'>Toolbox</Text></Text> window and select the  <Text as='b'><Text as='u'>History</Text></Text> tab.</ListItem>
                                <ListItem>Right click on the table and set your desired period.</ListItem>
                                <ListItem>Right click on the table and select <Text as='b'><Text as='u'>Report</Text></Text> {' > '} <Text as='b'><Text as='u'>HTML</Text></Text> to export.</ListItem>
                                <ListItem>Choose the downloaded .HTML file below and click <Text as='b'>Upload</Text>.</ListItem>
                            </OrderedList>
                        </Flex>*/}

                        {/*<Flex direction="row" align='center' hidden={!broker} mt='40px' mb='20px'>
                            <Button variant='outline' colorScheme='blue' onClick={handleClick} borderRadius='5px'>Choose File</Button>
                            <Spacer />
                            <Input ref={chooser} type='file' display='none' onChange={handleFileChange} accept={fileType}></Input>
                            <Text ms='15px' me='15px'>{filename}</Text>
                            <Spacer />
                            <IconButton
                                justifySelf='end'
                                borderRadius='5px'
                                variant='outline'
                                colorScheme='red'
                                aria-label='clearfile'
                                icon={<Icon as={MdClose} w="20px" h="20px" />}
                                hidden={filename === ''}
                                onClick={() => { clearFile() }}
                            />
                        </Flex>
                        <Text ms='5px' mt='15px' hidden={!broker || !!filename}>{fileType.toUpperCase()} file required ...</Text>*/}
                        <Flex hidden={!broker} mt='30px'>
                            <FileDropZone setdropfile={updateFileChoice} broker={broker} />
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={() => { clearFile(); onClose() }}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" onClick={() => { onClose(); props.processfile(); }} hidden={filename === ''}>
                            Process
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default UploadBrokerModal;