import { ArrowForwardIcon } from "@chakra-ui/icons";
import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, Flex, AlertDialogBody, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Checkbox, Button, Text, useColorModeValue } from "@chakra-ui/react";
import { ChangeEvent, useRef, useState } from "react";
import { db } from "services/db";
import useNewInstrument from "./useNewInstrument";

export const useCommissionDialog = ({ }: any): [(t: string, p: string) => Promise<number>, () => JSX.Element] => {

    const [promise, setPromise] = useState<{ resolve: (value: number) => void } | null>(null);

    //const [commission, setCommission] = useState(0);
    const [instrument, setInstrument] = useState(useNewInstrument());
    const [commindex, setcommindex] = useState(null);
    const textColor = useColorModeValue('secondaryGray.700', 'white');
    const [defChecked, setDefChecked] = useState(true);
    const [t_platform, setT_platform] = useState('')
    const [loading, setLoading] = useState(false);

    const cancelRef = useRef()

    async function updateFee(t: string, p: string): Promise<number> {
        return new Promise(async (resolve, reject) => {

            db.instruments.get({ ticker: t }).then((i) => {

                setInstrument(i);

                switch (p) {
                    case 'ninja':
                        setT_platform("NINJATRADER");
                        setcommindex(0);
                        break;
                    case 'tvate':
                        while (instrument.commission.length < 2) {
                            instrument.commission.push(0);
                        }
                        setT_platform("TRADOVATE");
                        setcommindex(1);
                        break;
                    case 'tview':
                        while (instrument.commission.length < 3) {
                            instrument.commission.push(0);
                        }
                        setT_platform("TRADINGVIEW");
                        setcommindex(2);
                        break;
                    case 'paper':
                        while (instrument.commission.length < 4) {
                            instrument.commission.push(0);
                        }
                        setT_platform("MANUAL ENTRY");
                        setcommindex(3);
                        break;
                }

            }).catch(() => { })

            setPromise({ resolve });
        });
    }

    const handleConfirm = () => {
        setLoading(true);
        if (defChecked) {
            db.instruments.put(instrument, instrument.id);
        }
        promise?.resolve(instrument.commission[commindex] ? instrument.commission[commindex] : 0);//commission);
        handleClose();
    }

    const handleClose = () => {
        setPromise(null);
        setLoading(false);
    }

    const updateDefault = (e: ChangeEvent<HTMLInputElement>) => {
        setDefChecked(e.target.checked);
    }

    const updateCommission = (valueAsString: string, valueAsNumber: number) => {
        instrument.commission[commindex] = valueAsNumber
    }

    const CommissionDialog = () => {
        return (
            <>
                <AlertDialog leastDestructiveRef={cancelRef} isOpen={promise !== null} onClose={handleConfirm} motionPreset='slideInBottom' closeOnOverlayClick={false}>
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                <Flex direction={'column'} align={'center'}>
                                    <Text fontWeight={'700'} mb='20px'>{t_platform} <ArrowForwardIcon /> fee </Text>
                                    <Text fontWeight={'700'} color={'red.500'}>{instrument.description}</Text>
                                </Flex>
                            </AlertDialogHeader>
                            <AlertDialogBody>
                                <Flex justify='center'>
                                    <Text fontWeight={'500'} mb='20px' me='5px'>
                                        Confirm single unit execution fee for
                                    </Text>
                                    <Text fontWeight={'700'} mb='20px'>
                                        {instrument.ticker}
                                    </Text>
                                </Flex>
                                <Flex justify='center' mt='10px' mb='10px'>
                                    <NumberInput defaultValue={instrument.commission[commindex]} min={0} allowMouseWheel={true} onChange={updateCommission} step={0.01}>
                                        <NumberInputField color={textColor} placeholder="0.00" />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </Flex>
                                <Flex direction={'row'} justify={'space-between'} mt='30px' mb='20px'>
                                    <Checkbox isChecked={defChecked} onChange={updateDefault}>Save as default?</Checkbox>
                                    <Button ref={cancelRef} onClick={handleConfirm} colorScheme='teal' ml={3} isDisabled={loading} isLoading={loading} loadingText="Processing">
                                        Accept
                                    </Button>
                                </Flex>
                            </AlertDialogBody>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </>
        )
    };

    return [updateFee, CommissionDialog];
}