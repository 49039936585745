import { Button, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { TbUserEdit } from 'react-icons/tb'
import { updateDefaultGroup } from 'services/apiservice'
import { useAuth } from 'services/auth/AuthProvider'
import { db } from 'services/db'
import AccountEdit from 'views/trader/components/AccountEdit'
import useNewAccount from 'views/trader/trades/hooks/useNewAccount'
import { Account } from 'views/trader/trades/variables/types'

const AccountEditModal = (props: { accountid: number }) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { user, muuid } = useAuth();
    const [account, setAccount] = useState(useNewAccount());
    const toast = useToast();

    const updateAccountEdit = (edit: boolean) => {
        onClose();
    }

    const updateSelectedGroup = async (grpid: number) => {
        await updateDefaultGroup(grpid, user.id, muuid);
    }

    const openModal = async() =>{
        await db.accounts.get(props.accountid).then((acc)=>{
            setAccount(acc);
            onOpen();
        }).catch((e)=>{
            toast({ title: 'Unable to retrieve account ...', description: "", status: 'error', position: 'top', duration: 3000, isClosable: false })
        })
    }

    return (
        <>
            <Button onClick={openModal} size={'sm'} colorScheme={'teal'}>
                <Icon as={TbUserEdit} boxSize={5}></Icon>
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} size={'sm'} >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit account</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <AccountEdit account={account} updateEdit={updateAccountEdit} updateGroup={updateSelectedGroup} isModal={true}/>
                    </ModalBody>
      
                </ModalContent>
            </Modal></>
    )
}

export default AccountEditModal