import { Tabs, TabList, Tab, TabPanels, TabPanel, Flex, Box, Container } from '@chakra-ui/react'
import PeriodChartTrades from '../charts/PeriodChartTrades'
import { useData } from 'services/data/DataProvider';
import { useEffect, useState } from 'react';
import { PeriodSeriesData } from '../../variables/PeriodSeriesData';
import PeriodChartDailyWeekly from '../charts/PeriodChartDailyWeekly';
import Card from 'components/card/Card';

const PeriodCharts = (props: { hidden?: boolean, minHeight: string }) => {

    const { periodtrades, defaultcurrency } = useData();
    const [hidden, setHidden] = useState(false);
    const [trades, setTrades] = useState([{ label: "", nett: 0 }]);
    const [daily, setDaily] = useState([{ start: "", nett: 0 }]);
    const [weekly, setWeekly] = useState([{ start: "", nett: 0 }]);
    const [defaultTab, setDefaultTab] = useState(localStorage.getItem('pchartdefault') ? JSON.parse(localStorage.getItem('pchartdefault')) : 0);


    useEffect(() => {
        updateVariables();
    }, [periodtrades])

    const updateVariables = async () => {
        setHidden(!periodtrades.length);
        if (periodtrades.length) {
            await PeriodSeriesData(periodtrades, defaultcurrency).then((res) => {
                setTrades(res.trades);
                setDaily(res.daily);
                setWeekly(res.weekly);
            })
        }
    }
    const changeTabDefault = (e: number) => {
        localStorage.setItem('pchartdefault', JSON.stringify(e));
        setDefaultTab(e);
    }

    return (
        <>
            <Tabs  isFitted variant='enclosed' mt="10px" align='center' defaultIndex={defaultTab} hidden={props.hidden} onChange={changeTabDefault} >
                <TabList>
                    <Tab>Trades</Tab>
                    <Tab>Daily</Tab>
                    <Tab>Weekly</Tab>
                </TabList>
                <TabPanels borderWidth={'1px'} borderBottomRadius={'20px'}>
                    <TabPanel minH={'100%'}>
                        <PeriodChartTrades hidden={hidden} data={trades} minHeight={props.minHeight}/>
                    </TabPanel>
                    <TabPanel minH={'100%'}>
                        <PeriodChartDailyWeekly hidden={hidden} period='daily' data={daily} minHeight={props.minHeight}/>
                    </TabPanel>
                    <TabPanel minH={'100%'}>
                        <PeriodChartDailyWeekly hidden={hidden} period='weekly' data={weekly} minHeight={props.minHeight}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>

    )
}

export default PeriodCharts