/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Button, Flex, Icon, useColorModeValue, useDisclosure, useToast, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { SyncAPI } from '../components/syncapi/SyncApi';
import { db } from 'services/db';
import { useLoading } from 'services/loading/LoadingProvider';
import { useAuth } from 'services/auth/AuthProvider';
import { useData } from 'services/data/DataProvider';
import { AccountTableRow } from '../trades/variables/types';
import { getAccountRows } from './variables/AccountsTableData';
import AccountsTable from './components/AccountsTable';
import TableCard from '../trades/components/tables/TableCard';
import { Container as ModalContainer } from "react-modal-promise";
import { NewAccountModal } from '../trades/components/modals/AccountModal';
import useNewAccount from '../trades/hooks/useNewAccount';
import uuid from 'react-uuid'
import { TbUserBolt } from 'react-icons/tb';
// Custom components


export default function Default() {

  const { user, setUser, allowaccountadd } = useAuth();
  const { usertrades, accounts, accountgroups } = useData();
  const { loading, setLoading } = useLoading();

  const [rowdata, setRowData] = useState<AccountTableRow[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const newaccount = useNewAccount();
  const toast = useToast();
  const [warned, setWarned] = useState(false);


  // Chakra Color Mode
  const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');

  useEffect(() => {
    setLoading(true);
    SyncAPI(user.id).then(async (res) => {
      setLoading(false);
      await db.users.get(user.id).then((u) => {
        setUser(u);
      })
      setTimeout(() => {

      }, 250);
    });
  }, [])

  useEffect(() => {
    updateTableData();
  }, [accounts, accountgroups, usertrades])


  const updateTableData = async () => {
    setLoading(true);
    await getAccountRows(accounts, accountgroups, usertrades).then((res) => {
      setRowData(res);
      setLoading(false);
    })
  }

  const addaccount = async () => {

    //toast({ title: 'Please DO NOT add broker accounts here ...', description: "You will be prompted to add broker accounts when importing statements for the first time.", status: 'warning', position: 'top', duration: null, isClosable: true })


    await allowaccountadd().then(async (r: boolean) => {

      //alert(dayjs.unix(1735311971));

      if (r) {
        if (!warned) {
          toast({ title: 'Only add paper trading accounts manually', description: <Text whiteSpace="pre-line">{"\nPlease DO NOT add broker statement accounts here.\n\nYou will be prompted to add broker accounts automatically when importing statements for the first time.\n\n"}</Text>, status: 'error', position: 'top', duration: 10000, isClosable: true })
          setWarned(true);
        }
        let a = newaccount;
        a.brokerid = uuid();
        a.platform = 'paper';
        localStorage.setItem("newaccount", JSON.stringify(a)); // used in account modal

        try {
          await NewAccountModal({ isOpen });
        }
        catch (err) {
          toast({ title: 'Account creation cancelled ...', description: "", status: 'warning', position: 'top', duration: 3000, isClosable: false })
        }
        localStorage.removeItem("newaccount");
      }
      else {
        toast({ title: 'Maximum trading accounts reached ...', description: "Either delete an existing trading account or upgrade your subscription to create more trading accounts", status: 'error', position: 'top', duration: null, isClosable: true });
      }
    })

  }

  const paperbutton = () => {
    return <Button size='sm' variant={'outline'} onClick={addaccount}><Icon as={TbUserBolt} me='20px' boxSize={5}></Icon>Add PAPER account</Button>
  }

  return (
    <>
      <Flex direction={{ base: 'column', xl: 'row' }} pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex direction="column" width="stretch">
          <TableCard title='Trading accounts' table={<AccountsTable rows={rowdata} />} showTitleBar={true} button={paperbutton()} showBg={true} showMt={false} showPadding={true} loading={loading} minh={'auto'} />
        </Flex>
      </Flex>
      <ModalContainer />
    </>

  );
}


