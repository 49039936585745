
import Chart from 'react-apexcharts';
import { Box, Flex, FormLabel, ScaleFade, Text, useColorModeValue } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { Trade } from 'views/trader/trades/variables/types'
import { ApexOptions } from 'apexcharts';
import Card from 'components/card/Card';
import { currencyformat } from 'views/trader/trades/variables/FormatData';

const StrategiesChart = (props: { strats: { id: number, name: string, trades: Trade[], nett: number }[], currency: string, showtrades: any }) => {

    const [nochartdata, setnochartdata] = useState(false);
    const textColorSecondary = useColorModeValue('secondaryGray.700', 'secondaryGray.500');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const bdColor = useColorModeValue('gray.200', 'gray.700');
    const bgcolor = useColorModeValue('white', 'navy.900');

    const [height, setHeight] = useState(200);

    useEffect(() => {
        updateVariables();
    }, [props.strats, props.currency])

    const updateVariables = () => {

        let data = [];
        let axis = [];

        props.strats.sort((a, b) => b.trades.length - a.trades.length);

        for (let d of props.strats) {
            data.push(d.nett.toFixed(2))
            //let i = props.userstrategies.filter((s) => s.id === d.id);
            axis.push(d.name + " : " + currencyformat(d.nett, props.currency) + "  (" + d.trades.length + ")");
        }

        if (data.length) {
            let h = data.length * 60;
            setHeight(h > height ? h : height);
        }

        setnochartdata(!data.length);

        options.xaxis.categories = axis;
        options.dataLabels.background.foreColor = textColor;
        setVariables({ options: options, series: [{ data: data }] });
    }

    const options: ApexOptions = {
        chart: {
            toolbar: { show: false },
            events: {
                click: function (event, chartContext, config) {
                    if (config.dataPointIndex > -1) {
                        props.showtrades('strategy', config.dataPointIndex);
                    }
                }
            },
        },
        annotations: {
            xaxis: [{
                x: 0,
                strokeDashArray: 4,
                borderColor: '#848287',
                fillColor: '#848287',
                opacity: 0.3,
                offsetX: 0,
                offsetY: 0,

            }]
        },
        plotOptions: {
            bar: {
                barHeight: '90%',
                distributed: false,
                horizontal: true,
                borderRadius: 8,
                dataLabels: {
                    position: 'bottom'
                },
                colors: { ranges: [{ from: 0, to: 1000000, color: '#01B574' }, { from: -1000000, to: 0, color: '#EE5D50' }] }
            }
        },
        colors: ['#dc748b', '#d8637c', '#d4526e', '#be4963'],
        grid: { show: false },
        legend: { show: false },
        fill: {
            type: 'gradient',
            gradient: {
                type: 'vertical',
                shadeIntensity: 0.15,
                opacityFrom: 0.9,
                opacityTo: 1
            }
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'start',
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex];
            },
            offsetX: 4,
            background: {
                enabled: true,
                foreColor: textColor,
                padding: 0,
                borderRadius: 2,
                borderWidth: 8,
                opacity: 0.2,
                dropShadow: {
                    enabled: false
                }
            }
        },
        stroke: {
            width: 0
        },
        xaxis: {
            categories: [],
            labels: {
                show: true,
                style: {
                    colors: ['#848287'],
                    fontSize: '12px',
                    fontFamily: 'DM Sans',
                    fontWeight: 400,
                },
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            }
        },
        yaxis: {
            labels: {
                show: false
            }
        },
        tooltip: {
            theme: 'dark',
            x: {
                show: true
            },
            y: {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return currencyformat(value, props.currency)
                },
                title: {
                    formatter: () => "Nett: ",
                },
            }
        }
    }
    const [variables, setVariables] = useState({ options: options, series: [{ data: [] }] });


    return (
        <>
            <Card h={height} color={bdColor} borderWidth='1px' bg='transparent'>
                <Flex direction={'row'} justify={'space-between'}>
                    <FormLabel fontWeight='bold' fontSize='md' mb='8px' color={textColor} mt='-32px' bg={bgcolor} px='10px'>Strategies</FormLabel>
                    <FormLabel fontWeight='bold' fontSize='xs' color={textColorSecondary} mt='-30px' bg={bgcolor} px='10px'>Click bars to view trades</FormLabel>
                </Flex>
                <Flex hidden={!nochartdata} justify={'center'} align={'center'} >
                    <ScaleFade initialScale={0.5} in={true} transition={{ exit: { delay: 0.5 }, enter: { duration: 1 } }}>
                        <Box p='10px' color={textColorSecondary} >
                            <Text color={textColorSecondary} fontSize='md' mt='20px' mb='20px' ms='20px'>No data to display</Text>
                        </Box>
                    </ScaleFade>
                </Flex>
                <Chart
                    type='bar'
                    hidden={nochartdata}
                    options={variables.options}
                    width="100%"
                    height="95%"
                    series={variables.series}
                />
            </Card>

        </>
    )
}

export default StrategiesChart