import { db } from "services/db";
import { Execution, Trade, newTrade } from "./types";
import { exec } from "apexcharts";

export function processedTrade(execs: Execution[]): Promise<Trade> {

    return new Promise(async (resolve, reject) => {
        try {

            if(execs[0]===undefined){
                reject('')
            }


            const tickerDetails = await db.instruments.where("ticker").equalsIgnoreCase(execs[0].ticker.trim()).toArray();

            let tr = newTrade();

            let opentime: Date = new Date(execs[0].datetime);
            let closetime: Date = new Date(execs[execs.length - 1].datetime);

            //tr.date = [execs[0].datetime, (closetime.getTime() - opentime.getTime())];
            tr.datetime = execs[0].datetime;
            tr.duration = (closetime.getTime() - opentime.getTime());
            tr.ticker = execs[0].ticker;
            tr.currency = execs[0].currency;
            tr.side = execs[0].side;

            let size: number = execs[0].size;
            let fees: number = execs[0].fee;
            let entry_total = execs[0].price * execs[0].size;
            let exit_total = 0;

            for (let i = 1; i < execs.length; i++) {
                if (execs[i].side === execs[0].side) {
                    size += execs[i].size;
                    entry_total += execs[i].price * execs[i].size;
                }
                else {
                    exit_total += execs[i].price * execs[i].size;
                }
                fees += execs[i].fee;
            }

            let decimals = tickerDetails[0].ticksize.toString().length - 2;

            tr.size = size;
            tr.entry_avg = entry_total / size;
            tr.exit_avg = exit_total / size;
            tr.points = Number((tr.side === 'buy' ? (tr.exit_avg - tr.entry_avg) * tr.size : (tr.entry_avg - tr.exit_avg) * tr.size).toFixed(decimals));
            tr.profitloss = Number((tr.points * tickerDetails[0].pointvalue).toFixed(2));
            tr.fees = fees;
            tr.nett = tr.profitloss - tr.fees;
            tr.executions = execs;
            tr.closed = true;

            resolve(tr);
        }
        catch (err) {
            reject(err);
        }
    });



}