/* eslint-disable */

import {
	Flex, Table, Tbody, Td, Text,
	Th, Thead, Tr, useColorModeValue, Divider, IconButton,
	NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
	Select,
	Spinner,
	Icon,
	Badge,
} from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	SortingState,
	PaginationState,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
	getFilteredRowModel,
	FilterFn
} from '@tanstack/react-table';
// Custom components  
//import TradeModal from '../modals/TradeModal';

import { JAUser, Subscription, SubscriptionProduct } from 'views/trader/trades/variables/types';
import { useEffect, useState } from 'react';
import { MdCheck, MdClear, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';

import UserEnable from './UserEnable';
import dayjs from 'dayjs';
import UserManage from './UserDelete';
import UserStats from './UserStats';

const columnHelper = createColumnHelper<JAUser>();

export default function UserTable(props: { users: JAUser[], products: SubscriptionProduct[], filter: string }) {

	const [data, setData] = useState<JAUser[]>([]);
	const [loading, setLoading] = useState(false);

	const [sorting, setSorting] = useState<SortingState>([]);
	const textColor = useColorModeValue("navy.700", "white");
	const textColorSecondary = useColorModeValue('secondaryGray.700', 'secondaryGray.500');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	const [tablesize, setTablesize] = useState(localStorage.getItem('tablesize') ? Number(localStorage.getItem('tablesize')) : 10);

	useEffect(() => {
		table.setGlobalFilter(String(props.filter))
	}, [props.filter])

	const getInterval = (subs: Subscription) => {
		if (subs !== null) {
			let p = props.products.filter((pr) => pr.variant_id === subs.variant_id);
			return p[0].period.toString();
		}
		return "-"
	}

	const getStatus = (subs: Subscription) => {
		if (subs !== null) {
			switch (subs.status) {
				case 'active': return <Badge colorScheme={'green'} color={'green.500'} fontSize='sm' fontWeight='500'>{subs.status.toUpperCase()}</Badge>;
				case 'cancelled': return <Badge colorScheme={'red'} color={'red.500'} fontSize='sm' fontWeight='500'>{subs.status.toUpperCase()}</Badge>
				case 'expired': return <Badge fontSize='sm' fontWeight='500'>{subs.status.toUpperCase()}</Badge>
				case 'paused': return <Badge colorScheme={'orange'} color={'orange.500'} fontSize='sm' fontWeight='500'>{subs.status.toUpperCase()}</Badge>
				default: return <Badge fontSize='sm' fontWeight='500'>{subs.status.toUpperCase()}</Badge>
			}
		}
		return "-"

	}
	const getEndRenew = (subs: Subscription) => {
		if (subs !== null) {
			return subs.status === 'cancelled' ? dayjs(subs.ends_at).format("YYYY-MM-YY HH:MM") : dayjs(subs.renews_at).format("YYYY-MM-YY HH:MM");
		}
		return '-'
	}
	const getEndRenewCol = (subs: Subscription) => {
		if (subs !== null) {
			return subs.status === 'cancelled' ? 'red.500' : 'green.500';
		}
		return textColor;
	}

	const getVerified = (e: boolean) => {
		if (e) {
			return <Icon boxSize={5} as={MdCheck} color={'green.500'}></Icon>
		}
		else {
			return <Icon boxSize={5} as={MdClear} color={'red.500'}></Icon>
		}
	}
	const getEnabled = (e: boolean, userid: number) => {
		return <UserEnable userid={userid} initcheck={e} updateEnable={updatenable} />
	}

	const removeuser = (id: number) => {
		let update = data.slice();
		update = update.filter((u) => u.id !== id);
		setData(update);
	}
	const updatenable = (id: number, enabled: boolean) => {
		let update = data.slice();
		let u_index = update.findIndex((u) => u.id === id); // find user index in update
		let u = update[u_index] // get user
		u.enabled = enabled; // set enabled true/false
		update = [...update.slice(0, u_index), u, ...update.slice(u_index + 1)]; // replace user object in update
		setData(update);
	}

	const logincolor = (last: string) => {
		let days = dayjs().diff(last, 'day')
		return days < 7 ? 'green.500' : (days < 30 ? 'orange.500' : 'red.500');
	}
	const sortusers = (arr: number[]) => {
		return JSON.stringify(arr);
	}
	const usercolor = (userid: number) => {
		let users = data.filter((u) => u.devices.indexOf(userid) > -1);
		if (users.length > 1) {
			return 'red.500'
		}
		return textColor;
	}

	useEffect(() => {
		setData(props.users);
	}, [props.users])

	const columns = [
		columnHelper.accessor('id', {
			id: 'id',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					#
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontWeight={'500'}>{info.getValue()}</Text>
			)
		}),
		columnHelper.accessor('username', {
			id: 'username',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Username
				</Text>
			),
			cell: (info: any) => (
				<Text color={textColor} fontWeight={'500'}>{info.getValue()}</Text>
			)
		}),
		columnHelper.accessor('email', {
			id: 'email',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Email
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontWeight={'500'}>{info.getValue()}</Text>
			)
		}),
		columnHelper.accessor('ipaddress', {
			id: 'ipaddress',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					IP Address
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontWeight={'500'}>{info.getValue()}</Text>
			)
		}),
		columnHelper.accessor('devices', {
			id: 'devices',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Users
				</Text>
			),
			cell: (info) => (
				<Text color={usercolor(info.row.original.id)} fontWeight={'700'}>{sortusers(info.getValue())}</Text>
			)
		}),
		columnHelper.accessor('verified', {
			id: 'verified',
			enableGlobalFilter: false,
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					verified
				</Text>
			),
			cell: (info) => (
				getVerified(info.getValue())
			)
		}),
		columnHelper.accessor('enabled', {
			id: 'enabled',
			enableGlobalFilter: false,
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					enabled
				</Text>
			),
			cell: (info) => (
				getEnabled(info.getValue(), info.row.original.id)
			)
		}),
		columnHelper.accessor('ismfa', {
			id: 'ismfa',
			enableGlobalFilter: false,
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					MFA
				</Text>
			),
			cell: (info) => (
				getVerified(info.getValue())
			)
		}),
		columnHelper.accessor('lastlogin', {
			id: 'lastlogin',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Last login
				</Text>
			),
			cell: (info) => (
				<Text color={logincolor(info.getValue())} fontWeight={'700'}>{info.getValue()}</Text>
			)
		}),
		columnHelper.accessor('role', {
			id: 'role',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Plan
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontWeight={'500'}>{info.getValue()}</Text>
			)
		}),
		columnHelper.accessor('subscription', {
			id: 'subscription',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Interval
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontWeight={'500'}>{getInterval(info.getValue())}</Text>
			)
		}),
		columnHelper.accessor('subscription', {
			id: 'subscription',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Status
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontWeight={'500'}>{getStatus(info.getValue())}</Text>
			)
		}),

		columnHelper.accessor('subscription', {
			id: 'subscription',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Renews/ends
				</Text>
			),
			cell: (info) => (
				<Text color={getEndRenewCol(info.getValue())} fontWeight={'500'}>{getEndRenew(info.getValue())}</Text>
			)
		}),
		columnHelper.accessor('trades', {
			id: 'trades',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Trades
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontWeight={'500'}>{info.getValue()}</Text>
				
			)
		}),
		columnHelper.accessor('subscription', {
			id: 'usermanage',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
				</Text>
			),
			cell: (info) => (
				<>
					<UserManage traderid={info.row.original.id} deleteuser={removeuser} sub={info.row.original.subscription} />
					<UserStats traderid={info.row.original.id} />
				</>

			)
		}),

	];

	const updatePageSize = (size: number) => {
		setTablesize(size);
		localStorage.setItem('tablesize', size.toFixed());
		table.setPageSize(size);
	}
	const globalFilterFn: FilterFn<any> = (row, columnId, filterValue: any) => {
		let value = row.getValue(columnId) as string;
		if (typeof value === 'number') value = String(value);
		return String(value).toLowerCase().includes(filterValue);
	};

	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: tablesize,
	})
	const [columnVisibility, setColumnVisibility] = useState({});
	const [globalFilter, setGlobalFilter] = useState<any>([])

	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
			pagination,
			columnVisibility,
			globalFilter,
		},
		onColumnVisibilityChange: setColumnVisibility,
		onGlobalFilterChange: setGlobalFilter,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onPaginationChange: setPagination,
		globalFilterFn: globalFilterFn,
		debugTable: true
	});

	if (!data.length && (loading)) {
		return <Flex hidden={!loading} justify='center' padding='30px'>
			<Spinner thickness='4px' speed='0.65s' size='xl' />
		</Flex>
	}
	else {
		return (
			<>
				<Table variant='simple' color='gray.500' mt="12px">
					<Thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<Tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => {

									return (
										<Th
											key={header.id}
											colSpan={header.colSpan}
											pe='10px'
											borderColor={borderColor}
											cursor='pointer'
											onClick={header.column.getToggleSortingHandler()}>
											<Flex
												justifyContent='space-between'
												align='center'
												fontSize={{ sm: '10px', lg: '12px' }}
												color='gray.400'>
												{flexRender(header.column.columnDef.header, header.getContext())}{{
													asc: '',
													desc: '',
												}[header.column.getIsSorted() as string] ?? null}
											</Flex>
										</Th>
									);
								})}
							</Tr>
						))}
					</Thead>
					<Tbody>
						{table.getRowModel().rows.map((row) => {
							return (
								<Tr key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<Td
												key={cell.id}
												fontSize={{ sm: '14px' }}
												minW={{ sm: '150px', md: '200px', lg: 'auto' }}
												borderColor='transparent'>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</Td>
										);
									})}
								</Tr>
							);
						})}
					</Tbody>
				</Table>
				<Divider mt='10px' hidden={table.getRowCount() <= 10} />
				<Flex direction={{ base: 'column', md: 'row' }} justify='center' hidden={table.getRowCount() <= 10}>
					<Flex direction='row' justify='center' hidden={table.getRowCount() <= table.getState().pagination.pageSize}>
						<Text mt='23px' ms='20px' me='5px'>Page</Text>
						<Text as='b' mt='23px' ms='5px' me='20px'>{table.getState().pagination.pageIndex + 1} of{' '}{table.getPageCount().toLocaleString()}</Text>
						<Text mt='23px' color={textColorSecondary}>Go to page: </Text>
						<NumberInput ms='10px' mt='20px' size='sm' min={1} max={table.getPageCount()} w='85px' allowMouseWheel={true} hidden={table.getRowCount() <= table.getState().pagination.pageSize}
							onChange={(valueString) => table.setPageIndex(valueString ? Number(valueString) - 1 : 0)}
						>
							<NumberInputField color={textColor} borderRadius='5px' />
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
						</NumberInput>
					</Flex>
					<Flex direction='row' justify='center'>
						<Select size='sm' mt='20px' ms='10px' me='10px' borderRadius='5px' alignContent='end' w='120px' value={tablesize}//table.getState().pagination.pageSize}
							onChange={e => { updatePageSize(Number(e.target.value)) }}
						>
							{[10, 20, 30, 40, 50, 100].map(pageSize => (
								<option key={pageSize} value={pageSize}>
									Show {pageSize}
								</option>
							))}
						</Select>
						<Flex hidden={table.getRowCount() <= table.getState().pagination.pageSize}>
							<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardDoubleArrowLeft />} onClick={() => table.firstPage()} disabled={!table.getCanPreviousPage()} />
							<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardArrowLeft />} onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} />
							<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardArrowRight />} onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} />
							<IconButton mt='20px' me='5px' size='sm' borderRadius='5px' variant='outline' colorScheme='gray' aria-label='backward' icon={<MdKeyboardDoubleArrowRight />} onClick={() => table.lastPage()} disabled={!table.getCanNextPage()} />
						</Flex>
					</Flex>
				</Flex>
			</>
		);
	}
}
