import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormLabel, HStack, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useColorModeValue, useDisclosure, useRadio, useRadioGroup, useToast, VStack } from '@chakra-ui/react'
import Card from 'components/card/Card';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react'
import api from 'services/api';
import { db } from 'services/db';
import NotesTemplates from 'views/trader/trades/components/fields/NotesTemplates';
import { DayNote, Trade, TradeStrategy } from '../../../trades/variables/types';
import DashCalendarModalTrade from './DashCalendarModalTrade';
import uuid from 'react-uuid';
import DashCalendarModalChart from 'views/trader/dashboard/components/charts/DashCalendarModalChart';
import TradeDrawer from 'views/trader/trades/components/modals/TradeDrawer';
import { MdClose, MdDeleteForever } from 'react-icons/md';
import { saveTradesToDb, updateStratsAndMistakes } from 'services/dbservice';
import JornoQuill from 'views/trader/components/inputs/JornoQuill';
import useNewDaynote from 'views/trader/trades/hooks/useNewDaynote';
import { useData } from 'services/data/DataProvider';
import { useAuth } from 'services/auth/AuthProvider';
import { updateTrade } from 'services/apiservice';
import ImageDropzone from 'views/trader/trades/components/fields/ImageDropzone';

const DashCalendarModal = (props: { event: any }) => {

    const { defaultcurrency, dateformat, CalendarDateTrades, setselectedCalendarDate } = useData();
    const { muuid } = useAuth();
    const { event } = props.event;

    const { isOpen, onOpen, onClose } = useDisclosure()
    const bdColor = useColorModeValue('gray.200', 'gray.600');
    const bgColor = useColorModeValue('white', 'navy.800');
    const bgheadfoot = useColorModeValue('gray.200', 'navy.900');
    const [loading, setloading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [hidesave, setHidesave] = useState(true);
    const [trades, setTrades] = useState<Trade[]>([]);
    const [tempreset, setTempreset] = useState('');
    const [updatedStrategies, setUpdatedStrategies] = useState<any[]>([]);
    const [updatedMistakes, setUpdatedMistakes] = useState<any[]>([]);
    const [tradesPendingUpdate, setTradesPendingUpdate] = useState<Trade[]>([]);

    const [image, setImage] = useState<string>("");
    const [origImageLength, setOrigImageLength] = useState(0);

    const [modaltitle, setmodaltitle] = useState('');
    const biasoptions = ['Bullish', 'Neutral', 'Bearish']
    const [bias, setBias] = useState('');
    const newdaynote = useNewDaynote();

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'bias',
        value: bias
    })

    /*continue with image coding
    - need to add to local db and types
    - update api functions 
    - update db*/

    const group = getRootProps()

    const [notes, setNotes] = useState("");
    const [daynote, setDaynote] = useState<DayNote>(useNewDaynote());
    //const textAreaRef = useRef<HTMLTextAreaElement>(null);
    //useAutosizeTextArea(textAreaRef.current, notes);

    const tradeDrawerRef = useRef(null);

    const toast = useToast();

    const openModal = async () => {
        let df = dateformat.replace("HH:mm", '').replace("ss", '')

        setselectedCalendarDate(dayjs(event.start));

        setmodaltitle(dayjs(event.start).format(df.trim()));

        await db.daynotes.get({ date: dayjs(event.start).format("YYYY-MM-DD"), groupid: event.extendedProps.groupid }).then((n) => {
            if (n !== undefined) {
                setDaynote(n);
                setBias(n.bias);
                setNotes(n.text);
                setImage(n.base64Image ? n.base64Image : '');
                setOrigImageLength(n.base64Image ? n.base64Image.length : 0);
            }
            else {
                daynote.date = dayjs(event.start).format("YYYY-MM-DD");
            }
        }).catch(() => { })
        onOpen();
        //setloading(true);

        /*await db.trades.bulkGet(event.extendedProps.trades).then((tr) => {
            setTrades(tr);
            setloading(false);
        }).catch(() => { })*/


    }

    useEffect(() => {
        setTrades(CalendarDateTrades);
        setloading(false);
        //alert(JSON.stringify(CalendarDateTrades))
    }, [CalendarDateTrades])

    const closeModal = async () => {
        if (updatedStrategies.length || updatedMistakes.length) {
            await updateStratsAndMistakes(updatedStrategies, updatedMistakes).then((r) => {
                setNotes('');
                setDaynote(newdaynote);
                setBias('');
                setHidesave(true);
                setTradesPendingUpdate([]);
                onClose();
            });
        }
        else {
            setNotes('');
            setBias('');
            setHidesave(true);
            setDaynote(newdaynote);
            setTradesPendingUpdate([]);
            onClose();
        }
    }

    const setDayNotes = (content: string) => {//e: ChangeEvent<HTMLTextAreaElement>) => {
        setNotes(content.trim());

        let str = content.replace(/(<([^>]+)>)/ig, '').trim();
        setHidesave(!str.length && bias === '' && daynote.id === 0 && image==='');
    }

    const saveDaynote = async () => {

        if ((!notes.trim().length || notes.trim() === '<p><br></p>') && bias === '' && image === '') {
            if (daynote.id > 0) {
                deleteDayNote(false);
            }
        }
        else {
            setSaving(true);
            let tmpNotes = daynote.text;
            daynote.text = notes.trim();
            daynote.groupid = event.extendedProps.groupid;
            daynote.last_uuid = muuid;
            daynote.bias = bias;
            daynote.trades = event.extendedProps.trades;
            daynote.base64Image = image;


            delete daynote.sync_due;
            //delete daynote.image;

            await api.post('/trade/savedaynote', daynote)
                .then(async (res) => {

                    delete daynote.last_uuid;

                    if (res.data === false) {
                        if (daynote.id > 0) {
                            daynote.sync_due = true;
                            await db.daynotes.put(daynote, daynote.id).then(() => { }).catch(error => { });
                        }
                        toast({ title: 'Oops server error ...', description: "Try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: false });
                        daynote.text = tmpNotes;
                        setSaving(false);
                    }
                    else {
                        //res.data.sync_due = false;
                        //delete res.data.last_uuid;

                        daynote.id = res.data;
                        daynote.sync_due = false;
                        //res.data.image = '';
                        //await db.daynotes.put(res.data, res.data.id).catch(error => { });
                        await db.daynotes.put(daynote, daynote.id).catch(error => { });
                        setDaynote(daynote);
                        toast({ title: 'Day notes saved ...', description: "", status: 'success', position: 'top', duration: 2000, isClosable: false });
                        setSaving(false);
                        setTempreset(uuid())
                        setHidesave(true);
                    }

                }).catch(async (err) => {
                    if (daynote.id > 0) {
                        delete daynote.last_uuid;
                        daynote.sync_due = true;
                        await db.daynotes.put(daynote, daynote.id).then(() => { }).catch(error => { });
                    }
                    toast({ title: 'Oops error ...', description: "Note not saved, check network connection and try again", status: 'error', position: 'top', duration: 3000, isClosable: false });
                    daynote.text = tmpNotes;
                    setSaving(false);
                })
        }
    }
    const deleteDayNote = async (showtoast: boolean) => {

        setDeleting(true);

        delete daynote.sync_due;
        daynote.last_uuid = muuid;

        await api.post('/trade/deletedaynote', daynote)
            .then(async (res) => {
                if (res.data === true) {
                    await db.daynotes.delete(daynote.id).then(() => {
                        let newdn = newdaynote;
                        setTempreset(uuid());
                        newdn.date = dayjs(event.start).format("YYYY-MM-DD");
                        setDaynote(newdn);
                        setNotes("");
                        setBias("");
                        setImage("");
                        if (showtoast) {
                            toast({ title: 'Day notes deleted ...', description: "", status: 'error', position: 'top', duration: 2000, isClosable: false, icon: <Icon as={MdDeleteForever} boxSize={6} /> });
                        }
                        setHidesave(true);
                    })
                }
                else {
                    toast({ title: 'Oops error ...', description: "Note not deleted, try again in a minute", status: 'error', position: 'top', duration: 3000, isClosable: false });
                }
                setDeleting(false);
            }).catch(async (err) => {
                toast({ title: 'No server connection ...', description: "Check your network connection", status: 'error', position: 'top', duration: 3000, isClosable: false });
                setDeleting(false);
            })

            setHidesave(true);
    }


    const showTrade = async (tradeid: number) => {

        /// this blocked due to scrolling issue - scrolling is blocked

        let tr = trades.filter((t) => t.tradeid === tradeid);
        if (tr.length) {
            tradeDrawerRef.current.openModal(tr[0]);
        }
        else {
            toast({ title: 'Oops, trade not found ...', description: "Please refresh your browser window", status: 'error', position: 'top', duration: 2000, isClosable: false });
        }
        setTimeout(() => {
            closeModal();
        }, 1000);

    }

    const addtradeToPending = (tr: Trade) => {

        setTradesPendingUpdate([...tradesPendingUpdate, tr]);

        let up: Trade[] = trades.slice(); // copies array
        let index = trades.findIndex((t) => t.tradeid === tr.tradeid);
        if (index > -1) {
            up = [...up.slice(0, index), tr, ...up.slice(index + 1)];
            setTrades(up);
        }
    }

    const bulkTradeUpdate = async () => {
        setloading(true);
        for (let t of tradesPendingUpdate) {
            delete t.sync_due; // only used locally
            t.last_uuid = muuid;
            let str = t.notes.replace(/(<([^>]+)>)/ig, '').trim();
            if (!str.length) {
                t.notes = "";
            }
            t.notes = t.notes.trim();
        }

        if (tradesPendingUpdate.length) {
            await api.post('/trade/updatetrades', { trades: tradesPendingUpdate }).then(async (trades) => {
                if (trades.data !== false) {
                    await saveTradesToDb(trades.data, false).then(async (r) => {
                        //await reprocessStats(event.extendedProps.groupid).then((r) => {
                        closeModal();
                        //})
                    }).catch((e) => {
                        toast({ title: 'Oops, saving failed ...', description: "", status: 'info', position: 'top', duration: 2000, isClosable: false });
                    })
                }
                else {
                    toast({ title: 'Oops, server error ...', description: "Please try again in a few minutes", status: 'info', position: 'top', duration: 2000, isClosable: false });
                }
            }).catch((err) => {
                toast({ title: 'Oops, saving failed ...', description: "Please check your network connection", status: 'info', position: 'top', duration: 3000, isClosable: false });
            })
        }


        setTimeout(() => {
            setloading(false);
        }, 500);


    }

    const addStratToUpdate = (su: TradeStrategy) => {
        setUpdatedStrategies([...updatedStrategies, su]);
        trades.forEach((tr, index) => {
            tr.strategies.forEach((s) => {
                if (s.id === su.id) {
                    if (s.name.trim() !== su.name.trim()) {
                        s.name = su.name;
                    }
                }
            })
        })
    }

    const addmisttoupdate = (mu: { name: string; id: number }) => {
        setUpdatedMistakes([...updatedMistakes, mu]);
        trades.forEach((tr, i) => {
            const index = tr.mistakes.findIndex((m: { name: string, id: number }) => m.id === mu.id);
            if (index > -1) {
                let m: { name: string, id: number } = { name: mu.name, id: mu.id }
                let mist = tr.mistakes.slice();
                tr.mistakes = [...mist.slice(0, index), m, ...mist.slice(index + 1)];
            }
        });
    }

    const updateBias = (b: string) => {
        setBias(b === bias ? '' : b);
        setHidesave(false);
    }

    const savetrade = async (trade: Trade) => {

        //setLoading(true);

        delete trade.sync_due; //only used locally

        let str = trade.notes.replace(/(<([^>]+)>)/ig, '').trim();
        if (!str.length) {
            trade.notes = "";
        }
        trade.notes = trade.notes.trim();
        trade.last_uuid = muuid;

        await updateTrade(trade).then(async (r) => {
            trade.sync_due = r;
            await db.trades.put(trade, trade.tradeid).then(async () => {
                //updatetrade(trade);
            }).catch(error => { });
        });

        updateSandMonly();
    }
    const updateSandMonly = async () => {
        await updateStratsAndMistakes(updatedStrategies, updatedMistakes).then((r) => {
            //setLoading(false);
        });
    }

    const updateimage = async (file: string) => {
        if (file.length !== image.length || file.length !== origImageLength) {
            setHidesave(false);
        }
        
        setImage(file);
    }

    function RadioCard(props: any) {
        const { getInputProps, getRadioProps } = useRadio(props)
        const input = getInputProps()
        const checkbox = getRadioProps()

        let col = '';
        switch (props.children) {
            case 'Bullish': col = 'green.500'; break;
            case 'Bearish': col = 'red.500'; break;
            default: col = 'gray.500'
        }

        return (
            <Box as='label'>
                <input {...input} />
                <Box
                    {...checkbox}
                    cursor='pointer'
                    borderWidth='1px'
                    borderRadius='md'
                    boxShadow='md'
                    _checked={{
                        bg: col,
                        color: 'white',
                        borderColor: col,
                    }}
                    _focus={{
                        boxShadow: 'outline',
                    }}
                    px={3}
                    py={1}
                    onClick={() => updateBias(props.children)}
                >
                    {props.children}
                </Box>
            </Box>
        )
    }

    return (
        <>
            <Box as={Button} height={'auto'} bg={event.backgroundColor} w='100%' borderRadius={'5px'} p={'10px'} onClick={openModal} _hover={{ background: 'rgba(0, 0, 0, 0.25);' }}>
                <Flex direction={'column'}>
                    <Text fontSize={{ base: 'xs', md: 'md' }} color={'white'}>{event.title}</Text>
                    <Text fontSize={'xs'} color={'gray.200'} mt='5px'>
                        {event.extendedProps.trades.length} {event.extendedProps.trades.length > 1 ? 'trades' : 'trade'}
                    </Text>
                    {/* only display R if stops entered */}
                    {event.extendedProps.avgR !== 0 && <Text fontSize={'xs'} color={'gray.200'} mt='5px'>{'('}{event.extendedProps.avgR.toFixed(2)}{' R)'}</Text>}
                </Flex>
            </Box>
            <Drawer isOpen={isOpen} onClose={onClose} size={{ base: 'full', md: 'lg' }} placement='left'>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader bg={bgheadfoot}>
                        <Flex direction={'row'}>
                            <Box as={Button} isLoading={loading} height={'40px'} fontSize={'md'} bg={event.backgroundColor} borderRadius={'5px'} me='25px' color={'white'}>NETT: {event.title}</Box>
                            <Text mt='5px'>{modaltitle}</Text>
                        </Flex>
                    </DrawerHeader>
                    <DrawerCloseButton autoFocus onClick={closeModal} isDisabled={loading} />
                    <DrawerBody bg={bgColor}>
                        <VStack gap={2}>
                            <Card bg={bgColor} borderWidth='1px' borderColor={bdColor}>

                                <Flex direction={{ base: 'column', md: 'row' }} justify={'space-between'}>
                                    <HStack {...group} mb='15px'>
                                        <FormLabel fontWeight='bold' fontSize='md' mt='9px'>BIAS:</FormLabel>
                                        {biasoptions.map((value) => {
                                            const radio = getRadioProps({ value })
                                            return (
                                                <RadioCard key={value} {...radio}>
                                                    {value}
                                                </RadioCard>
                                            )
                                        })}
                                    </HStack>
                                    <Flex justify={'end'} mt={{ base: '0px', md: '4px' }} mb={{ base: '5px', md: '0px' }}>
                                        <NotesTemplates type="day" notes={notes} setnotes={setDayNotes} reset={tempreset} mb={'8px'} size={'sm'} />
                                    </Flex>
                                </Flex>
                                <JornoQuill content={notes} updatecontent={setDayNotes} allowimages={false} placeHolder={'Enter day notes here ...'} />
                                <Flex direction={'column'}>

                                    <Flex direction={'row'} justify={'space-between'}>
                                        <FormLabel fontWeight='bold' fontSize='sm' mb='-10px' mt='10px'>Day Image</FormLabel>
                                        <Flex hidden={!image.length} w="30px" h="30px" justify="center" align="center" bg={'red.500'} borderColor={'red.500'} borderWidth={'2px'} borderRadius="10px" mt='10px' mb='-10px' top="15px" right="15px" transition="0.3s" cursor="pointer" onClick={() => updateimage('')} _hover={{ background: 'rgba(0, 0, 0, 0.25);' }}>
                                            <Icon as={MdClose} color="#fff" fontSize="20px" />
                                        </Flex>
                                    </Flex>
                                    <ImageDropzone imagefile={image} setimagefile={updateimage} />
                                </Flex>
                                <Flex justify={'end'}>
                                    <Button size={'sm'} mt={'20px'} borderRadius='5px' colorScheme='blue' onClick={saveDaynote} isLoading={saving} hidden={hidesave}> {/*daynote.text.trim() === notes.trim() && !saving && daynote.bias === bias*/}
                                        Save changes
                                    </Button>
                                    <Button size={'sm'} mt={'20px'} borderRadius='5px' colorScheme='red' variant={'outline'} onClick={() => deleteDayNote(true)} isLoading={deleting} ms='5px' hidden={daynote.id === 0}>
                                        <Icon as={MdDeleteForever} boxSize={5} me={'5px'} /> Delete entry
                                    </Button>
                                </Flex>
                            </Card>
                            <Card bg={bgColor} borderWidth='1px' borderColor={bdColor}>
                                <FormLabel fontWeight='bold' fontSize='md' mb='8px'>Trades</FormLabel>
                                {trades.map(tr => (
                                    <DashCalendarModalTrade key={tr.tradeid} trade={tr} opentrade={showTrade} />
                                ))}
                            </Card>
                            <Card bg={bgColor} borderWidth='1px' borderColor={bdColor}>
                                <DashCalendarModalChart trades={trades} currency={defaultcurrency} opentrade={showTrade} />
                            </Card>
                        </VStack>
                    </DrawerBody>
                    <DrawerFooter bg={bgheadfoot} hidden={true}>
                        <Button colorScheme='blue' onClick={bulkTradeUpdate} me='5px' loadingText='Saving' isLoading={loading}>
                            Save changes
                        </Button>
                        <Button colorScheme='red' variant={'outline'} onClick={closeModal} >
                            Cancel
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <>
                {/*<TradeDrawer ref={tradeDrawerRef} addUpdatedStrategy={addStratToUpdate} addUpdatedMistake={addmisttoupdate} bulkupdate={true} setpendingupdate={addtradeToPending}></TradeDrawer>*/}
                <TradeDrawer ref={tradeDrawerRef} addUpdatedStrategy={addStratToUpdate} addUpdatedMistake={addmisttoupdate} savetrade={savetrade} bulkupdate={false} updateSandMonly={updateSandMonly}></TradeDrawer>
            </>
        </>
    )
}

export default DashCalendarModal