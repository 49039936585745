import { Box, Flex, ScaleFade, Text, useColorModeValue } from '@chakra-ui/react'
import { ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { currencyformat } from 'views/trader/trades/variables/FormatData';

const PeriodChartDailyWeekly = (props: { data: { start: string, nett: number }[], period: string, hidden: boolean, minHeight: string }) => {

    const textColorSecondary = useColorModeValue('secondaryGray.700', 'secondaryGray.500');

    const { data, period, hidden } = props;

    useEffect(() => {

        let axis = [];
        let cddata = [];
        let x_annotations = [];

        for (let d of data) {
            axis.push(d.start);
            cddata.push(Number(d.nett.toFixed(2)));


        }

        options.plotOptions.bar.borderRadius = (data.length > 40 ? 1 : (data.length > 10 ? 5 : 8));
        options.xaxis.categories = axis;

        setVariables({ options: options, series: [{ data: cddata }] });

    }, [data])

    const options: ApexOptions = {
        chart: {
            toolbar: { show: false },
            redrawOnParentResize: true
        },
        plotOptions: {
            bar: {
                borderRadius: 1,
                colors: {
                    ranges: [{
                        from: -1000000,
                        to: 0,
                        color: '#EE5D50'
                    }, {
                        from: 0,
                        to: 1000000,
                        color: '#01B574'
                    }]
                },
                columnWidth: '80%',
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                type: 'horizontal',
                shadeIntensity: 0.15,
                opacityFrom: 0.9,
                opacityTo: 1
            }
        },
        dataLabels: {
            enabled: false,
        },
        grid: { show: false },
        legend: { show: false },
        annotations: {
            yaxis: [{
                y: 0,
                strokeDashArray: 6,
                borderColor: '#848287',
                fillColor: '#848287',
                opacity: 0.5,
                offsetX: 0,
                offsetY: 0,
            }]
        },
        xaxis: {
            categories: [],
            labels: {
                show: false,
                style: {
                    colors: ['#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287'],
                    fontSize: '12px',
                    fontFamily: 'DM Sans',
                    fontWeight: 400,
                },
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            }
        },
        yaxis: {
            show: true,
            labels: {
                show: true,
                style: {
                    colors: ['#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287', '#848287'],
                    fontSize: '12px',
                    fontWeight: 400,
                },
            }
        },
        tooltip: {
            theme: 'dark',
            x: {
                show: true
            },
            y: {
                formatter: function (value: number, { series, seriesIndex, dataPointIndex, w }: any) {
                    return currencyformat(value, 'USD')
                },
                title: {
                    formatter: () => "Nett: ",
                },
            }
        }
    }


    const [variables, setVariables] = useState({ options: options, series: [] });

    return (
        <>
            <Box minH={props.minHeight} p='0px'>
                <Flex justify={'center'} align={'center'} hidden={!hidden}>
                    <ScaleFade initialScale={0.5} in={true} transition={{ exit: { delay: 0.5 }, enter: { duration: 1 } }}>
                        <Box p='10px' color={textColorSecondary} >
                            <Text color={textColorSecondary} fontSize='md' mt='20px' mb='20px' ms='20px'>No data to display</Text>
                        </Box>
                    </ScaleFade>
                </Flex>
                <Chart
                    hidden={hidden}
                    type='bar'
                    options={variables.options}
                    width="100%"
                    height="100%"
                    series={variables.series}
                />
            </Box>
        </>
    )
}

export default PeriodChartDailyWeekly