// Chakra Imports
import {
  Button,
  Box,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  useToast,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  useDisclosure,
  DrawerOverlay,
  Select,
  FormLabel,
  Checkbox
} from '@chakra-ui/react';

// Assets
import {
  MdEdit,
  MdKey,
  MdOutlinePermIdentity
} from 'react-icons/md';

import { ChangeEvent, useEffect, useState } from "react";

import { HSeparator } from 'components/separator/Separator';

import SignOut from './confirm/SignOut';
import { Account, AccountGroup } from '../trades/variables/types';
import DeleteAccountConfirm from './confirm/DeleteAccountConfirm';
import SyncData from './syncapi/SyncData';
import DeleteProfile from './confirm/DeleteProfileConfirm';
import Container from "react-modal-promise";
import { UsernameInput } from './inputs/UpdateUsername';
import { PasswordUpdate } from './inputs/UpdatePassword';
import AccountEdit from './AccountEdit';
import DeleteGroupConfirm from './confirm/DeleteGroupConfirm';
import api from 'services/api';
import { setDefaultAccount, setDefaultGroup, setGroupAccountsSyncDue, setGroupsSyncDue } from 'services/dbservice';
import { updateDefaultGroup } from 'services/apiservice';
import dayjs from 'dayjs';
import AccountGroupEdit from '../trades/components/modals/AccountGroupEdit';
import { useAuth } from 'services/auth/AuthProvider';
import TotpModal from './TotpModal';
import UserInstrumentModal from './UserInstrumentModal';
import { useLoading } from 'services/loading/LoadingProvider';
import { useData } from 'services/data/DataProvider';
import Card from 'components/card/Card';

export default function AccountDrawer(props: { [x: string]: any }) {

  const { user, muuid } = useAuth();
  const { setLoading } = useLoading();
  const { defaultgroup, defaultaccount, accountgroups, groupAccounts, dateformat, updatedateformat, usefees, usestops, updateUsefees, updateUsestops } = useData();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const navbarIcon = useColorModeValue('gray.400', 'white');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const bgSeparator = useColorModeValue('secondaryGray.400', '#323B5D');
  const drawerBg = useColorModeValue('white', 'navy.800');
  const configuratorShadow = useColorModeValue(
    '-20px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '-22px 32px 51px 4px #0B1437',
  );
  const [accountEdit, setAccountEdit] = useState(false);
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const [username, setusername] = useState(user.username);
  const toast = useToast();

  const { signout } = useAuth();

  useEffect(() => {
    if (!user.username) {
      signout(false);
    }
  }, [defaultaccount])

  const accountUpdate = async (e: ChangeEvent<HTMLSelectElement>) => {

    setAccountEdit(false);

    setLoading(true);

    await setDefaultAccount(Number(e.target.value), defaultgroup.groupid, user.id).then(async (accid) => {
      await api.post('/accounts/defaultaccount', { accountid: Number(e.target.value), groupid: defaultgroup.groupid, uuid: muuid })
        .then(async (res) => {
          if (res.data === false) {
            await setGroupAccountsSyncDue(defaultgroup.groupid, true);
          }
        })
        .catch(async (error) => {
          await setGroupAccountsSyncDue(defaultgroup.groupid, true);
        });
    }).catch(() => { });
    setLoading(false);

  }

  /*const updateSelectedAccount = (accountid: number) => {
    db.accounts.get(accountid).then((a) => {
      if (a === undefined) {
        setSelectedAccount(newaccount);
      }
      else {
        setSelectedAccount(a);
      }
    })
  }*/

  const groupUpdate = async (e: ChangeEvent<HTMLSelectElement>) => {
    let grpid = Number(e.target.value);
    updateSelectedGroup(grpid);
  }

  const updateSelectedGroup = async (grpid: number) => {

    setLoading(true);

    await updateDefaultGroup(grpid, user.id, muuid);
    /*await setDefaultGroup(grpid, user.id).then(async () => {

      //setSelectedAccount(newaccount)
      setAccountEdit(false);

      await api.post('/accounts/defaultgroup', { groupid: grpid, uuid: muuid })
        .then(async (res) => {
          if (res === undefined) {
            await setGroupsSyncDue(true);
          }
        })
        .catch(async (error) => {
          await setGroupsSyncDue(true);
        });
    }).catch(() => { });*/
    setLoading(false);

  }

  const editUsername = async () => {
    try {
      let u = await UsernameInput();
      setusername(u);
    }
    catch (err) {
      if (err === 0) {
        toast({ title: 'Sorry ... unable to update username', description: "", status: 'error', position: 'top', duration: 3000, isClosable: false })
      }
    }
  }
  const editPassword = async () => {
    try {
      await PasswordUpdate();
    }
    catch (err) {
    }
  }

  const closeDrawer = () => {
    //setSelectedAccount(newaccount)
    setAccountEdit(false);
    onClose();
  }
  const openDrawer = () => {
    //updateSelectedAccount(defaultaccount ? defaultaccount.accountid : 0);
    onOpen();
  }
  const updateAccountEdit = (edit: boolean) => {
    setAccountEdit(edit);
  }

  const updateDateFormat = (e: ChangeEvent<HTMLSelectElement>) => {
    //localStorage.setItem("dateformat", e.target.value);
    //setDateFormat(e.target.value);
    updatedateformat(e.target.value);

  }
  function getFormattedDate(format: string) {
    return dayjs().format(format);
  }
  const updateFees = (e: ChangeEvent<HTMLInputElement>) => {
    updateUsefees( e.target.checked);
  }
  const updateStops = (e: ChangeEvent<HTMLInputElement>) => {
    updateUsestops( e.target.checked);
  }

  return (
    <>
      <Button variant="no-hover" bg="transparent" p="0px" minW="unset" minH="unset" h="25px" w="max-content" onClick={openDrawer}>
        <Icon me="10px" h="35px" w="35px" color={navbarIcon} as={MdOutlinePermIdentity} />
      </Button>
      <Container />
      <Drawer isOpen={isOpen} onClose={closeDrawer} placement={document.documentElement.dir === 'rtl' ? 'left' : 'right'} blockScrollOnMount={false}>
        <DrawerOverlay />
        <DrawerContent boxShadow={configuratorShadow} w={{ base: 'calc(100vw - 32px)', md: '400px' }} maxW={{ base: 'calc(100vw - 32px)', md: '400px' }} ms={{ base: '0px', sm: '16px' }} me={{ base: '16px' }} my={{ sm: '16px' }} borderRadius="16px" bg={drawerBg} >
          <DrawerHeader w={{ base: '100%', md: '400px' }} pt="24px" pb="0px" px="24px">
            <DrawerCloseButton color={textColor} />
            <Flex alignItems="center">
              <Flex h="30px" w="30px" me="20px">
                <Icon me="10px" h="30px" w="30px" color={navbarIcon} as={MdOutlinePermIdentity} />
              </Flex>
              <Box>
                <Text display={'flex'} color="secondaryGray.600" fontSize="lg" fontWeight="500">
                  Account settings
                </Text>
              </Box>
            </Flex>
            <Flex direction="row" justify={'space-between'} mt='40px'>
              <Text borderColor={borderColor} fontSize="md" fontWeight="700" color={textColor} mt='10px'>
                👋&nbsp; Hi {username}
              </Text>
              <Flex direction='row'>

                <Button size='md' onClick={editUsername} me='10px'>
                  <Icon size={10} color={navbarIcon} as={MdEdit} />
                </Button>
                <Button size='md' onClick={editPassword}>
                  <Icon size={10} color={navbarIcon} as={MdKey} />
                </Button>
                <TotpModal />
              </Flex>
            </Flex>
            <HSeparator my="30px" bg={bgSeparator} />
          </DrawerHeader>
          <DrawerBody pt="0px" pb="24px" w={{ base: '100%', md: '400px' }} maxW="unset">
            <Flex direction="column" justifyContent={'space-between'} height='100%'>
              <Flex direction='column'>
                <Flex direction="column" hidden={!accountgroups.length}>
                  <FormLabel fontSize='md' mb='8px' color="secondaryGray.600">Trading group</FormLabel>
                  <Flex direction={'row'}>
                    <AccountGroupEdit groupid={defaultgroup.groupid} />
                    <Select mb="20px" onChange={groupUpdate} value={defaultgroup.groupid} borderLeftRadius='0px' borderRightRadius={groupAccounts.length < 1 ? '0px' : '10px'}>
                      {accountgroups.map((grp: AccountGroup) => (
                        <option key={grp.groupid} value={grp.groupid} >
                          {grp.groupname}
                        </option>
                      ))}
                    </Select>

                    <DeleteGroupConfirm hidden={!!groupAccounts.length} groupid={defaultgroup.groupid} />
                  </Flex>
                </Flex>
                <Flex direction="column" hidden={!groupAccounts.length}>
                  <FormLabel fontSize='md' mb='8px' color="secondaryGray.600">Trading account</FormLabel>
                  <Flex direction="row">
                    <Button hidden={!defaultaccount} variant='outline' onClick={() => updateAccountEdit(!accountEdit)} borderLeftRadius={'10px'} borderRightRadius='0px'>
                      <Icon as={MdEdit} boxSize={5} />
                    </Button>
                    {groupAccounts.length > 0 && <Select mb="20px" onChange={accountUpdate} borderLeftRadius={defaultaccount ? '0px' : '10px'} borderRightRadius={defaultaccount ? '0px' : '10px'} value={defaultaccount ? defaultaccount.accountid : 0}>
                      <option key={0} value={0}>ALL Accounts</option>
                      {groupAccounts.map((acc: Account) => (
                        <option key={acc.accountid} value={acc.accountid}>
                          {acc.accname}
                        </option>
                      ))}
                    </Select>}
                    {defaultaccount && <DeleteAccountConfirm accountid={defaultaccount.accountid} resetEdit={() => setAccountEdit(false)} table={false}/>}
                  </Flex>
                </Flex>
                <Flex hidden={!accountEdit}>
                  {defaultaccount && <AccountEdit account={defaultaccount} updateEdit={updateAccountEdit} updateGroup={updateSelectedGroup} isModal={false}/>}
                </Flex>
              </Flex>
              <Flex direction={'column'} mb='50px'>
                <FormLabel fontSize='md' mb='8px' color="secondaryGray.600">Trade importing defaults</FormLabel>
                <Card borderWidth={'1px'} mb='20px' bg={'transparent'}>
                  <Flex direction={'row'} justify={'space-evenly'}>
                    <Checkbox isChecked={usefees} onChange={updateFees}>Input fees</Checkbox>
                    <Checkbox isChecked={usestops} onChange={updateStops}>Input stops</Checkbox>
                  </Flex>
                </Card>

                <Flex direction={'column'}>
                  <FormLabel fontSize='md' mb='8px' color="secondaryGray.600">Preferred date format</FormLabel>
                  <Select mb='20px' onChange={updateDateFormat} value={dateformat} borderRadius={'10px'}>
                    <option value="YYYY.MM.DD HH:mm:ss">{getFormattedDate('YYYY.MM.DD HH:mm:ss')}</option>
                    <option value="YYYY.MM.DD HH:mm">{getFormattedDate('YYYY.MM.DD HH:mm')}</option>
                    <option value="YYYY-MM-DD HH:mm:ss">{getFormattedDate('YYYY-MM-DD HH:mm:ss')}</option>
                    <option value="YYYY-MM-DD HH:mm">{getFormattedDate('YYYY-MM-DD HH:mm')}</option>
                    <option value="YYYY/MM/DD HH:mm:ss">{getFormattedDate('YYYY/MM/DD HH:mm:ss')}</option>
                    <option value="YYYY/MM/DD HH:mm">{getFormattedDate('YYYY/MM/DD HH:mm')}</option>
                    <option value="MM.DD.YYYY HH:mm:ss">{getFormattedDate('MM.DD.YYYY HH:mm:ss')}</option>
                    <option value="MM.DD.YYYY HH:mm">{getFormattedDate('MM.DD.YYYY HH:mm')}</option>
                    <option value="MM-DD-YYYY HH:mm:ss">{getFormattedDate('MM-DD-YYYY HH:mm:ss')}</option>
                    <option value="MM-DD-YYYY HH:mm">{getFormattedDate('MM-DD-YYYY HH:mm')}</option>
                    <option value="MM/DD/YYYY HH:mm:ss">{getFormattedDate('MM/DD/YYYY HH:mm:ss')}</option>
                    <option value="MM/DD/YYYY HH:mm">{getFormattedDate('MM/DD/YYYY HH:mm')}</option>
                    <option value="M/D/YY HH:mm">{getFormattedDate('M/D/YY HH:mm')}</option>
                    <option value="M/D/YY HH:mm:ss">{getFormattedDate('M/D/YY HH:mm:ss')}</option>
                    <option value="M/D/YYYY HH:mm">{getFormattedDate('M/D/YYYY HH:mm')}</option>
                    <option value="M/D/YYYY HH:mm:ss">{getFormattedDate('M/D/YYYY HH:mm:ss')}</option>
                    <option value="DD.MM.YYYY HH:mm:ss">{getFormattedDate('DD.MM.YYYY HH:mm:ss')}</option>
                    <option value="DD.MM.YYYY HH:mm">{getFormattedDate('DD.MM.YYYY HH:mm')}</option>
                    <option value="DD-MM-YYYY HH:mm:ss">{getFormattedDate('DD-MM-YYYY HH:mm:ss')}</option>
                    <option value="DD-MM-YYYY HH:mm">{getFormattedDate('DD-MM-YYYY HH:mm')}</option>
                    <option value="DD/MM/YYYY HH:mm:ss">{getFormattedDate('DD/MM/YYYY HH:mm:ss')}</option>
                    <option value="DD/MM/YYYY HH:mm">{getFormattedDate('DD/MM/YYYY HH:mm')}</option>
                    <option value="MMM D YYYY HH:mm">{getFormattedDate('MMM D YYYY HH:mm')}</option>
                  </Select>
                </Flex>
                <UserInstrumentModal islarge={true} />
                <SyncData />
                <DeleteProfile />
                <SignOut />
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
