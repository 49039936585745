
import {
    useDisclosure,
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Text,
    Flex,
    Icon,
    useToast,
    List,
    ListItem,
    ListIcon,
    Heading
} from '@chakra-ui/react'
import { useNetworkState } from '@uidotdev/usehooks';

import { useRef, useState } from "react";
import { IoMdCloseCircle } from 'react-icons/io';
import { MdCheckCircle, MdSync } from 'react-icons/md';
import { IoIosNuclear } from "react-icons/io";
import { ReSyncAPI } from './ReSyncApi';
import { useAuth } from 'services/auth/AuthProvider';
import { db } from 'services/db';
import { useLoading } from '../../../../services/loading/LoadingProvider';
import { useData } from 'services/data/DataProvider';
import api from 'services/api';
import useJornoUuid from 'views/trader/trades/hooks/useJornoUuid';
import { resolve } from 'path/posix';

const SyncData = () => {

    const { user, setUser } = useAuth();
    const { loading, setLoading } = useLoading();
    const toast = useToast();
    const network = useNetworkState();
    const { updatesyncing } = useData();
    const [loadtext, setloadtext] = useState('Downloading');
    const uuid = useJornoUuid();

    const handleResync = async () => {
        if (network.online) {
            setloadtext('Downloading');
            setLoading(true);
            updatesyncing(true);

            await api.post('/sync/resyncapi', { uuid: uuid }).then(async(res)=>{
                if (res.status === 200) {
                    setloadtext('Processing');
                    await ReSyncAPI(res).then(async (r) => {
                        onClose();
                        window.location.reload();
                        await db.users.get(user.id).then((u) => {
                            setUser(u);
                        })
                    }).catch((err) => {
                        onClose();
                        toast({ title: 'Server unavailable ...', description: "Please try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: false });
                    });
                }
                setLoading(false);
                updatesyncing(false);
            })       
        }
        else {
            setLoading(false);
            onClose();
            toast({ title: 'No network connection ...', description: "Make sure your device is connected to the internet", status: 'error', position: 'top', duration: 3000, isClosable: false });
        }
    };

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()

    return (
        <>
            <Button colorScheme='blue' variant='outline' onClick={onOpen} mb='20px'>
                <Icon as={MdSync} h='20px' w='20px' me='10px'></Icon>
                Re-Sync Data
            </Button>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} closeOnOverlayClick={false}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            <Flex direction={'row'} align={'center'}>
                                <Icon as={IoIosNuclear} h='35px' w='35px' me='10px' color={'red.500'}></Icon>
                                <Heading size={'md'}>Re-sync data</Heading>
                            </Flex>
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <Flex direction={'column'}>
                                <Text fontWeight={'700'} mb='20px'>
                                    This is the nuclear option to re-sync your history incase you've run into problems with your data!
                                </Text>
                                <Text fontWeight={'700'} mb='20px'>
                                    This action will ...
                                </Text>
                                <List spacing={3} mb='20px'>
                                    <ListItem>
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        Refetch all saved data from the server
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={IoMdCloseCircle} color='red.500' />
                                        Replace all your local data
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={IoMdCloseCircle} color='red.500' />
                                        Any un-synced updates will be lost
                                    </ListItem>


                                </List>
                                <Text fontWeight={'700'}>
                                    Are you sure?
                                </Text>
                            </Flex>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={handleResync} ml={3} isLoading={loading} loadingText={loadtext}>
                                Re-sync now
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default SyncData