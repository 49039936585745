import { Card, Flex, Heading, Spacer, useColorModeValue, Image } from '@chakra-ui/react'

const BrokerCard = (props:{broker: string, arr: any, brokerchange:any}) => {

    const bgcolor = useColorModeValue('transparent', 'transparent');
    const bgselectcolor = useColorModeValue('gray.200', 'navy.800');
    const bdcolor = useColorModeValue('gray.200', 'gray.600');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    
    return (
        <>
            <Card
                bg={props.broker === props.arr.platform ? bgselectcolor : bgcolor}
                height="70px"
                width={'full'}
                shadow="md"
                cursor={'pointer'}
                alignItems="center"
                justifyContent="center"
                borderRadius="20px"
                textAlign="center"
                borderWidth={'1px'}
                borderColor={bdcolor}
                onClick={() => props.brokerchange(props.arr.platform)}
            >
                <Flex direction={'row'} alignItems="center">
                    <Image src={props.arr.image} h={props.arr.height} me="2" />
                    <Heading size="sm" fontWeight="bold" color={textColor}>
                        {props.arr.text}
                    </Heading>
                </Flex>
            </Card>
            <Spacer />
        </>
    )
}

export default BrokerCard