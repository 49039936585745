/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import api from 'services/api';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs'

// Chakra imports
import {
  Box,
  Button,
  Link,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Text,
  useToast,
  Stack,
  useColorMode,
  PinInput,
  PinInputField
} from '@chakra-ui/react';
// Assets
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';

// Custom components
import CenteredAuth from 'layouts/auth/variants/Centered';
import { db } from 'services/db';
import { newStats } from 'views/trader/trades/variables/types';
import { useAuth } from 'services/auth/AuthProvider';
import RecoveryConfirm from 'views/trader/components/confirm/RecoveryConfirm';

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const toast = useToast();
  const navigate = useNavigate();
  const { signin, setUser } = useAuth();

  const [show, setShow] = React.useState(false);
  const [uname, setUname] = React.useState("");
  const [unverified, setUnverified] = React.useState(false);
  const [isAutheticating, setIsAutheticating] = React.useState(false);
  const [thumbprint, setThumbprint] = useState("");
  const handleClick = () => setShow(!show);

  const [needmfa, setneedmfa] = useState(false);//localStorage.getItem('mfa') ? JSON.parse(localStorage.getItem('mfa')) : false);

  const handleResend = () => {
    verifyresend();
  };

  useEffect(() => {
    getFingerprint().then((fp) => { setThumbprint(fp) });
  }, [])

  function verifyresend() {
    api.post('/auth/verifyresenduser', { username: uname })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.message === "resent") {
            toast({ title: 'Resent verification email', description: "Check your junk folder if you can't find it.", status: 'success', position: 'top', duration: null, isClosable: true, })
          }
          if (res.data.message === "failed") {
            toast({ title: 'Verification failed', description: "This verification link is invalid", status: 'error', position: 'top', duration: null, isClosable: false, })
          }
        }
      }).catch(function (error) {
        toast({ title: 'Resending email failed', description: "Please try resend again in a few minutes", status: 'error', position: 'top', duration: null, isClosable: true })
      });
  }

  return (
    <CenteredAuth cardTop={{ base: '60px', md: '14vh' }} cardBottom={{ base: '50px', lg: 'auto' }} mx="0px">
      <Flex maxW={{ base: '100%', md: 'max-content' }} w="100%" mx={{ base: 'auto', lg: '0px' }} me="auto" justifyContent="center" px={{ base: '20px', md: '0px' }} flexDirection="column">
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">Sign In</Heading>
          <Text hidden={unverified} mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">Enter your email and password to sign in!</Text>
          <Text hidden={!unverified} mb="36px" ms="4px" mt="25px" color={textColor} fontWeight="400" fontSize="md">Please first verify your email!</Text>
          <Text hidden={!unverified} mb="36px" ms="4px" mt="25px" color={textColor} fontWeight="400" fontSize="md">Check your emails {"("}including your junk folder{")"} for the verification email.</Text>

          <Text hidden={!unverified} color={textColorDetails} fontWeight="400" fontSize="14px" mx={{ base: 'auto', lg: 'unset' }} textAlign={{ base: 'center', lg: 'left' }} >
            Haven't received it?
            <Link color={textColorBrand} as="span" ms="5px" fontWeight="500" onClick={handleResend}>
              Resend verification email
            </Link>
          </Text>

        </Box>
        <Flex zIndex="2" direction="column" w={{ base: '100%', md: '420px' }} maxW="100%" background="transparent" borderRadius="15px" mx={{ base: 'auto', lg: 'unset' }} me="auto" mb={{ base: '40px', md: 'auto' }}>
          <Formik
            initialValues={{
              username: "",
              password: "",
              pin1: "",
              pin2: "",
              pin3: "",
              pin4: "",
              pin5: "",
              pin6: ""
            }}
            onSubmit={(values) => {
              setIsAutheticating(true);

              let otp = values.pin1 + values.pin2 + values.pin3 + values.pin4 + values.pin5 + values.pin6;
              let payload = { username: values.username, password: values.password, otp: otp, thumbprint: thumbprint };

              signin(payload).then(async (res: any) => {
                if (res === 400 || res === 401) {
                  toast({ title: 'Login failed', description: "Check your credentials and try again", status: 'error', position: 'top', duration: 3000, isClosable: false })
                  setIsAutheticating(false);
                }
                else if (res.status === 200) {
                  if (res.data.message === "disabled") {
                    toast({ title: 'Account has been disabled', description: <Text whiteSpace="pre-line">{"\nPlease click to contact "}<a href='mailto:support@tradejorno.zohodesk.com.au'><strong><u>SUPPORT</u></strong></a>{" for further assistance.\n"}</Text>, status: 'error', position: 'top', duration: null, isClosable: true })
                    //toast({ title: 'Account has been disabled', description: "Please contact support", status: 'error', position: 'top', duration: 3000, isClosable: false })
                    setIsAutheticating(false);
                  }
                  else if (res.data.message === "unverified") {
                    toast({ title: 'Verification needed', description: "Please check your emails (including junk folder)", status: 'error', position: 'top', duration: 3000, isClosable: false })
                    setUname(values.username);
                    setUnverified(true);
                    setIsAutheticating(false);
                  }
                  else if (res.data.message === "otp") {
                    setneedmfa(true);
                    toast({ title: 'Enter one-time PIN ...', description: "", status: 'warning', position: 'top', duration: 3000, isClosable: false });
                    setIsAutheticating(false);
                  }
                  else if (res.data.message === "expired") {
                    setneedmfa(true);
                    toast({ title: 'One-time PIN expired...', description: "Please request a fresh recovery code", status: 'warning', position: 'top', duration: 3000, isClosable: false });
                    setIsAutheticating(false);
                  }
                  else if (res.data.message === "badcreds") {
                    toast({ title: 'Bad Credentials ...', description: "", status: 'error', position: 'top', duration: 3000, isClosable: false });
                    setIsAutheticating(false);
                  }
                  else {
                    res.data.current_user = true;

                    await db.users.put(res.data, res.data.id).then(async () => {
                      await db.users.toCollection().modify(user => {
                        user.current_user = (user.id === res.data.id ? 1 : 0);
                      }).catch((err) => { });

                    }).catch((e) => { })

                    setUser(res.data);
                    delete res.data.subscription; // so it's not saved in localstorage
                    localStorage.setItem('juser', JSON.stringify(res.data));

                    let newStat = newStats();
                    db.statistics.put(newStat, newStat.id).then(() => {
                      navigate("/trader/dashboard");
                    }).catch(error => {
                      toast({ title: 'Browser error ...', description: "Cannot initialise local database", status: 'error', position: 'top', duration: null, isClosable: true })
                    });
                  }
                }
              }).catch(function (error: any) {
                toast({ title: 'Oops ... could not sign you in', description: "Please try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: false })
                setIsAutheticating(false);
              });
            }}
            validationSchema={Yup.object().shape({
              needmfa: Yup.boolean(),
              username: Yup.string().required('Please enter username.').min(5, 'Username minimum 5 characters').max(20, 'Username maximum 20 characters'),
              password: Yup.string().required('Please enter password.').min(10, 'Password minimum 10 characters').max(40, 'Password maximum 40 characters'),
              pin1: Yup.string().when([], {
                is: () => needmfa === true,
                then: (schema) => Yup.string().required('One-time PIN required ...').test("One-time PIN required", "One-time PIN required", (schema) => {
                  return schema.length > 0;
                }),
                otherwise: (schema) => schema.notRequired(),
              }),
              pin2: Yup.string().when([], {
                is: () => needmfa === true,
                then: () => Yup.string().required('One-time PIN required ...').test("One-time PIN required", "One-time PIN required", (schema) => {
                  return schema.length > 0;
                }),
                otherwise: (schema) => schema.notRequired(),
              }),
              pin3: Yup.string().when([], {
                is: () => needmfa === true,
                then: () => Yup.string().required('One-time PIN required ...').test("One-time PIN required", "One-time PIN required", (schema) => {
                  return schema.length > 0;
                }),
                otherwise: (schema) => schema.notRequired(),
              }),
              pin4: Yup.string().when([], {
                is: () => needmfa === true,
                then: () => Yup.string().required('One-time PIN required ...').test("One-time PIN required", "One-time PIN required", (schema) => {
                  return schema.length > 0;
                }),
                otherwise: (schema) => schema.notRequired(),
              }),
              pin5: Yup.string().when([], {
                is: () => needmfa === true,
                then: () => Yup.string().required('One-time PIN required ...').test("One-time PIN required", "One-time PIN required", (schema) => {
                  return schema.length > 0;
                }),
                otherwise: (schema) => schema.notRequired(),
              }),
              pin6: Yup.string().when([], {
                is: () => needmfa === true,
                then: () => Yup.string().required('One-time PIN required ...').test("One-time PIN required", "One-time PIN required", (schema) => {
                  return schema.length > 0;
                }),
                otherwise: (schema) => schema.notRequired(),
              }),
            })}
          >
            {({ handleSubmit, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <FormControl hidden={unverified}>

                  <FormControl isInvalid={!!errors.username && touched.username}>
                    <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                      Username<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Field
                      as={Input}
                      id="username"
                      name="username"
                      variant="auth" fontSize="sm" ms={{ base: '0px', md: '0px' }} type="text" placeholder="Enter username" fontWeight="500" size="lg"
                    />
                    <FormErrorMessage mb="24px">{errors.username}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.password && touched.password}>
                    <Stack direction="row" justify="space-between">
                      <FormLabel ms="4px" mt="24px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
                        Password<Text color={brandStars}>*</Text>
                      </FormLabel>
                      <NavLink to="/auth/forgotpassword">
                        <Text color={textColorSecondary} fontSize="sm" w="124px" fontWeight="500" mt="24px" tabIndex={-1}>
                          Forgot password?
                        </Text>
                      </NavLink>
                    </Stack>

                    <InputGroup size="md">
                      <Field as={Input} id="password" name="password" type={show ? 'text' : 'password'} fontSize="sm" ms={{ base: '0px', md: '4px' }} placeholder="Min. 10 characters" size="lg" variant="auth" />
                      <InputRightElement display="flex" alignItems="center" mt="4px">
                        <Icon color={textColorSecondary} _hover={{ cursor: 'pointer' }} as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye} onClick={handleClick} />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                  </FormControl>

                  <FormControl hidden={!needmfa} isInvalid={needmfa && (!!errors.pin1 && touched.pin1) || (!!errors.pin2 && touched.pin2) || (!!errors.pin3 && touched.pin3) || (!!errors.pin4 && touched.pin4) || (!!errors.pin5 && touched.pin5) || (!!errors.pin6 && touched.pin6)}>
                    <Stack direction="row" justify="space-between">
                      <FormLabel ms="4px" mt="24px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
                        One-time PIN<Text color={brandStars}>*</Text>
                      </FormLabel>
                      <RecoveryConfirm />
                    </Stack>
                    <Flex justify="center">
                      <PinInput otp size='sm'>
                        <Field as={PinInputField} type="text" variant="auth" id="pin1" name="pin1" fontSize="25px" color={textColor} borderRadius="16px" borderColor={borderColor} h='60px' w='60px' me="10px"></Field>
                        <Field as={PinInputField} type="text" variant="auth" id="pin2" name="pin2" fontSize="25px" color={textColor} borderRadius="16px" borderColor={borderColor} h='60px' w='60px' me="10px"></Field>
                        <Field as={PinInputField} type="text" variant="auth" id="pin3" name="pin3" fontSize="25px" color={textColor} borderRadius="16px" borderColor={borderColor} h='60px' w='60px' me="10px"></Field>
                        <Field as={PinInputField} type="text" variant="auth" id="pin4" name="pin4" fontSize="25px" color={textColor} borderRadius="16px" borderColor={borderColor} h='60px' w='60px' me="10px"></Field>
                        <Field as={PinInputField} type="text" variant="auth" id="pin5" name="pin5" fontSize="25px" color={textColor} borderRadius="16px" borderColor={borderColor} h='60px' w='60px' me="10px"></Field>
                        <Field as={PinInputField} type="text" variant="auth" id="pin6" name="pin6" fontSize="25px" color={textColor} borderRadius="16px" borderColor={borderColor} h='60px' w='60px' me="0px"></Field>
                      </PinInput>
                    </Flex>
                    <FormErrorMessage>{errors.pin1 || errors.pin2 || errors.pin3 || errors.pin4 || errors.pin5 || errors.pin6}</FormErrorMessage>
                  </FormControl>


                  <Flex justifyContent="space-between" align="center" mb="24px">

                  </Flex>
                  <Button type="submit" fontSize="sm" variant="brand" fontWeight="500" w="100%" h="50" mb="24px" isLoading={isAutheticating} loadingText='Authenticating'>Sign In</Button>

                </FormControl>
              </form>
            )}
          </Formik>

          <Flex flexDirection="column" justifyContent="center" alignItems="start" maxW="100%" mt="0px">
            <Text hidden={unverified} color={textColorDetails} fontWeight="400" fontSize="14px">Not registered yet?
              <NavLink to="/auth/signup">
                <Text color={textColorBrand} as="span" ms="5px" fontWeight="600">Create an Account</Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </CenteredAuth>
  );
}

export default SignIn;
