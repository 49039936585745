import { Button, Checkbox, Flex, FormControl, FormErrorMessage, Text, Heading, Icon, Input, InputGroup, InputLeftAddon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import banner from 'assets/img/auth/banner.png';
import MentorBannerEdit from './profile/MentorBannerEdit';
import { ChangeEvent, useState } from 'react';
import Card from 'components/card/Card';
import TextMaxLength from 'views/trader/trades/components/fields/TextMaxLength';
import { FaSquareXTwitter } from 'react-icons/fa6';
import { TbBrandYoutubeFilled } from 'react-icons/tb';
import { FiInstagram } from 'react-icons/fi';
import { SlGlobe } from 'react-icons/sl';
import StudentShare from 'views/trader/trades/components/fields/StudentShare';
import { useAuth } from 'services/auth/AuthProvider';
import api from 'services/api';
import { MentorProfile } from 'views/trader/trades/variables/types';
import useNewMentorProfile from 'views/trader/trades/hooks/useNewMentorProfile';


const MentorProfileModal = () => {

    const { user } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const bgcol = useColorModeValue('white', 'gray.700');
    const bgcolor = useColorModeValue('white', 'navy.900');
    const toast = useToast();
    const [loading, setloading] = useState(false);
    const [saving, setsaving] = useState(false);

    const [name, setName] = useState('')
    const [description, setDescription] = useState('This is a short description to highlight mentors attributes and get to know them mentors attributes and get to know them')
    const [s_show, sets_show] = useState(true);
    const [t_show, sett_show] = useState(true);
    const [j_show, setj_show] = useState(true);
    const [listed, setlisted] = useState(false);

    const [strategies, setstrategies] = useState('');
    const [trades, settrades] = useState('');
    const [journals, setjournals] = useState('');

    const [x_url, set_x_url] = useState('');
    const [y_url, set_y_url] = useState('');
    const [i_url, set_i_url] = useState('');
    const [w_url, set_w_url] = useState('');

    const [avatar, setAvatar] = useState('');

    const [mentor, setMentor] = useState<MentorProfile>(useNewMentorProfile());

    const openProfile = async () => {
        setloading(true);
        await api.post('/mentor/getprofile').then(async (res) => {
            //alert(JSON.stringify(res.data).length);
            if (res.data !== false) {
                let mp: MentorProfile = res.data;
                setMentor(mp);
                setAvatar(mp.avatar);
                setName(mp.name);
                setDescription(mp.description);
                sets_show(mp.s_show);
                sett_show(mp.t_show);
                setj_show(mp.j_show);
                setlisted(mp.listed);
                set_x_url(mp.x_handle);
                set_y_url(mp.yt_handle);
                set_i_url(mp.ig_handle);
                set_w_url(mp.weburl);
                setstrategies(statstring(mp.strategies));
                settrades(statstring(mp.trades));
                setjournals(statstring(mp.journals));
                setloading(false);
                onOpen();
            }
            else {
                toast({ title: 'Unable to load profile ...', description: "Please try again in a few minutes", status: 'error', position: 'top', duration: 5000, isClosable: false });
                setloading(false);
            }
        }).catch((e) => {
            toast({ title: 'Unable to load profile ...', description: "Please try again in a few minutes", status: 'error', position: 'top', duration: 5000, isClosable: false });
            setloading(false);
        })
    }

    const saveprofile = async () => {

        setsaving(true);

        delete mentor.strategies;
        delete mentor.trades;
        delete mentor.students;
        delete mentor.journals;
        delete mentor.username;

        await api.post('/mentor/saveprofile', mentor)
            .then(async (res) => {
                if (res.data === mentor.id) {
                    toast({ title: 'Profile saved', description: "", status: 'success', position: 'top', duration: 2000, isClosable: false });
                    onClose();
                }
                else {
                    toast({ title: 'Error saving profile ...', description: "Please try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: false });
                }
            }).catch(async (err) => {
                toast({ title: 'Error saving profile ...', description: "Please try again in a few minutes", status: 'error', position: 'top', duration: 3000, isClosable: false });
            })
        setsaving(false);
    }

    const statstring = (n: number) => {
        if (n > 1000) {
            return (n / 1000).toFixed(1) + "k";
        }
        else {
            return n.toString();
        }
    }

    const updateName = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        mentor.name = e.target.value;
    }
    const updateDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.target.value);
        mentor.description = e.target.value;
    }
    const showstrat = (e: ChangeEvent<HTMLInputElement>) => {
        sets_show(e.target.checked);
        mentor.s_show = e.target.checked;
    }
    const showstrades = (e: ChangeEvent<HTMLInputElement>) => {
        sett_show(e.target.checked);
        mentor.t_show = e.target.checked;
    }
    const showjournals = (e: ChangeEvent<HTMLInputElement>) => {
        setj_show(e.target.checked);
        mentor.j_show = e.target.checked;
    }
    const updateX = (e: ChangeEvent<HTMLInputElement>) => {
        set_x_url(e.target.value.trim());
        mentor.x_handle = e.target.value.trim();
    }
    const updateY = (e: ChangeEvent<HTMLInputElement>) => {
        set_y_url(e.target.value.trim());
        mentor.yt_handle = e.target.value.trim();
    }
    const updateI = (e: ChangeEvent<HTMLInputElement>) => {
        set_i_url(e.target.value.trim());
        mentor.ig_handle = e.target.value.trim();
    }
    const updateW = (e: ChangeEvent<HTMLInputElement>) => {
        set_w_url(e.target.value.trim());
        mentor.weburl = e.target.value.trim();
    }
    const updateAvatar = (src: string) => {
        setAvatar(src);
        mentor.avatar = src;
    }
    const updateShare = (share: boolean) => {
        if (share) {
            toast({ title: 'Listing your mentor profile', description: "Your profile will be visible to other TradeJorno users", status: 'info', position: 'top', duration: 4000, isClosable: false });
        }
        else {
            toast({ title: 'De-listing your mentor profile', description: "Your profile will NOT be visible to other TradeJorno users", status: 'error', position: 'top', duration: 4000, isClosable: false });
        }
        setlisted(user.role === "MENTOR" ? share : false);
        mentor.listed = user.role === "MENTOR" ? share : false;
    }
    const testUrl = (url: string, site: string) => {
        if (url.length) {
            switch (site) {
                case 'x': window.open("https://x.com/" + url, '_blank'); break;
                case 'yt': window.open("https://youtube.com/" + url, '_blank'); break;
                case 'ig': window.open("https://instagram.com/" + url, '_blank'); break;
                case 'self': window.open("https://" + url, '_blank'); break;
            }
        }
        else {
            toast({ title: 'Enter a valid ' + (site === 'self' ? "url" : 'handle') + ' ...', description: "", status: 'error', position: 'top', duration: 2000, isClosable: false });
        }
    }
    
    return (
        <>
            <Button variant={'solid'} borderWidth={'1px'} bg={bgcolor} onClick={openProfile} zIndex={10} isLoading={loading}>
                Mentor Profile
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'} closeOnOverlayClick={false} >
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>Mentor profile</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex>
                            <MentorBannerEdit
                                gridArea='1 / 1 / 2 / 2'
                                banner={banner}
                                avatar={avatar}
                                name={name}
                                username={user.username}
                                description={description}
                                strategies={strategies}
                                s_show={s_show}
                                trades={trades}
                                t_show={t_show}
                                journals={journals}
                                j_show={j_show}
                                x_url={x_url}
                                y_url={y_url}
                                i_url={i_url}
                                w_url={w_url}
                                updateAvatar={updateAvatar}
                            />
                        </Flex>
                        <Card borderWidth={'1px'} bg={'transparent'} mt='20px'>
                            <Flex>
                                <Heading size={'sm'} mt='-32px' px='10px' bg={bgcol}>Edit profile</Heading>
                            </Flex>
                            <Card borderWidth={'1px'} bg={'transparent'} mt='10px'>
                                <Flex>
                                    <Heading size={'xs'} mt='-30px' px='10px' bg={bgcol}>Socials</Heading>
                                </Flex>
                                <InputGroup mt='10px'>
                                    <InputLeftAddon><Icon as={FaSquareXTwitter} boxSize={7} color={'white'} /><Text ms='10px'>handle</Text></InputLeftAddon>
                                    <Input type='text' color={textColorPrimary} placeholder='eg @...' onChange={updateX} value={x_url} borderRightRadius={'0px'} maxLength={50}></Input>
                                    <Button colorScheme={'blue'} variant={'outline'} borderRadius={'5px'} borderLeftRadius={'0px'} onClick={() => testUrl(x_url, 'x')}>TEST</Button>
                                </InputGroup>
                                <InputGroup mt='10px'>
                                    <InputLeftAddon><Icon as={TbBrandYoutubeFilled} boxSize={7} color={'white'} /><Text ms='10px'>handle</Text></InputLeftAddon>
                                    <Input type='text' color={textColorPrimary} placeholder='eg @...' onChange={updateY} value={y_url} borderRightRadius={'0px'} maxLength={50}></Input>
                                    <Button colorScheme={'blue'} variant={'outline'} borderRadius={'5px'} borderLeftRadius={'0px'} onClick={() => testUrl(y_url, 'yt')}>TEST</Button>
                                </InputGroup>
                                <InputGroup mt='10px'>
                                    <InputLeftAddon><Icon as={FiInstagram} boxSize={7} color={'white'} /><Text ms='10px'>handle</Text></InputLeftAddon>
                                    <Input type='text' color={textColorPrimary} placeholder='without the @' onChange={updateI} value={i_url} borderRightRadius={'0px'} maxLength={50}></Input>
                                    <Button colorScheme={'blue'} variant={'outline'} borderRadius={'5px'} borderLeftRadius={'0px'} onClick={() => testUrl(i_url, 'ig')}>TEST</Button>
                                </InputGroup>
                                <InputGroup mt='10px'>
                                    <InputLeftAddon><Icon as={SlGlobe} boxSize={7} color={'white'} /><Text ms='10px'>weburl</Text></InputLeftAddon>
                                    <Input type='text' color={textColorPrimary} placeholder='eg: www.yourwebsite.com' onChange={updateW} value={w_url} borderRightRadius={'0px'} maxLength={150}></Input>
                                    <Button colorScheme={'blue'} variant={'outline'} borderRadius={'5px'} borderLeftRadius={'0px'} onClick={() => testUrl(w_url, 'self')}>TEST</Button>
                                </InputGroup>
                            </Card>

                            <FormControl isInvalid={!name.trim().length} mt='20px'>
                                <Flex justify={'end'} mb='5px'>
                                    <FormErrorMessage>Name required</FormErrorMessage>
                                </Flex>

                                <InputGroup >
                                    <InputLeftAddon>Name</InputLeftAddon>
                                    <Input type='text' color={textColorPrimary} maxLength={50} placeholder='Enter your name ...' onChange={updateName} value={name}></Input>
                                </InputGroup>
                            </FormControl>

                            <Card borderWidth={'1px'} bg={'transparent'} mt='30px'>
                                <Flex>
                                    <Heading size={'xs'} mt='-30px' px='10px' bg={bgcol}>Show</Heading>
                                </Flex>
                                <Flex direction={'row'} justify={'space-between'}>
                                    <Checkbox size='md' colorScheme='blue' isChecked={s_show} onChange={showstrat} >
                                        Strategies
                                    </Checkbox>
                                    <Checkbox size='md' colorScheme='blue' isChecked={t_show} onChange={showstrades} >
                                        Trades
                                    </Checkbox>
                                    <Checkbox size='md' colorScheme='blue' isChecked={j_show} onChange={showjournals} >
                                        Journals
                                    </Checkbox>
                                </Flex>
                            </Card>
                            
                            <Heading size={'xs'} mt='20px' mb='8px'>Description</Heading>
                            <Textarea value={description} rows={5} onChange={updateDescription} maxLength={250}></Textarea>
                            <TextMaxLength text={description} maxlength={250} justify={'right'}></TextMaxLength>
                        </Card>
                    </ModalBody>
                    <ModalFooter>
                        <Flex direction={{ base: 'column', md: 'row' }} justify={'end'}>
                            <Flex mb={{ base: '20px', md: '0px' }}>
                                <StudentShare checked={listed} updateshare={updateShare} label='Listed in mentor directory' />
                            </Flex>

                            <Flex direction={'row'} justify={'end'}>
                                <Button colorScheme='red' variant={'outline'} mr={3} onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button variant='ghost' onClick={saveprofile} isLoading={saving}>SAVE</Button>
                            </Flex>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default MentorProfileModal