import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { StatStrat } from 'views/trader/trades/variables/types';

const WinGuage2 = (props: { stats: StatStrat }) => {

    const { stats } = props;

    useEffect(() => {
        let newd = [Number(stats.wperc.toFixed())]
        setState({
            ...state,
            series: newd
          })
    }, [stats])

    const [state, setState] = useState({
        series: [],
        options: {
            chart: {
                offsetY: -10
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        show: true,
                        background: "#848287",
                        strokeWidth: '100%',
                        opacity: 0.1,
                        margin: 0, // margin is in pixels
                    },
                    dataLabels: {
                        name: {
                            fontSize: '12px',
                            fontFamily: 'DM Sans',
                            color: '#848287',
                            offsetY: 0
                        },
                        value: {
                            offsetY: -40,
                            fontSize: '16px',
                            fontFamily: 'DM Sans',
                            color: '#848287',
                        }
                    }
                }
            },
            fill: {
                opacity: 1.5,
                type: 'gradient',
                gradient: {
                    type: 'diagonal1',
                    shadeIntensity: 1,
                    opacityFrom: 1,
                    opacityTo: 2,
                    colorStops: [[{ offset: 0, color: '#EE5D50', opacity: 1 }, { offset: 120, color: '#01B574', opacity: 1 }]],
                    inverseColors: false
                },
            },

            stroke: {
                dashArray: 4
            },
            labels: ['Win %'],
        },


    });

    return (
        <>
            <Chart
                options={state.options}
                series={state.series}
                type='radialBar'
                width="100%"
                height="auto"
            />

        </>
    )
}

export default WinGuage2