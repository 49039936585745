import { Flex, Box, Text, useColorModeValue, Spinner } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { useEffect, useState } from 'react';

const TableCard = (props: { title: any, table: any, button?: any, showBg: boolean, showMt: boolean, showTitleBar: boolean, titleFontSize?: string, showPadding: boolean, loading: boolean, minh:string }) => {

    const textColor = useColorModeValue('secondaryGray.500', 'white');
    const spinnerColor = useColorModeValue('gray.300', 'brand.300');

    const [size, setSize] = useState('xl');

    useEffect(() => {
        switch (props.titleFontSize) {
            case 'xl': setSize('xl'); break;
            case 'lg': setSize('lg'); break;
            case 'md': setSize('md'); break;
            case 'sm': setSize('sm'); break;
            case 'xs': setSize('xs'); break;
        }
    }, [props.titleFontSize])

    return (
        <Card mt={props.showMt === true ? '15px' : '0px'} bg={'transparent'} p={props.showPadding ? '20px' : '5px'} borderWidth={'1px'} minH={props.minh} >
            <Flex direction='column' w='100%' overflowX={'auto'}>
                <Flex hidden={props.showTitleBar === false} align={{ lg: 'center' }} justify={{ base: 'space-between' }} w='100%' px='20px' mb='20px' direction={{base:'column', md:'row'}}>
                    <Spinner thickness='4px' speed='0.65s' color={spinnerColor} size='md' hidden={!props.loading} />
                    <Text fontSize={size} fontWeight='600' lineHeight='100%' mt='7px' hidden={props.loading} mb={{base:'20px', md:'0px'}}>
                        {props.title}
                    </Text>
                    {props.button}
                </Flex>
                <Box>
                    {props.table}
                </Box>
            </Flex>
        </Card>
    )
}

export default TableCard

/* overflowX={{ sm: 'scroll', '2xl': 'hidden' }} */