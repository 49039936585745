import { Icon } from '@chakra-ui/react';
import { MdLock, MdOutlineDashboardCustomize, MdInsights, MdHome, MdOutlineAddChart } from 'react-icons/md';

import { BsJournalBookmark, BsClipboardData } from "react-icons/bs";
import { TbUserBolt, TbUserCheck, TbUsers } from 'react-icons/tb';

// Admin Imports
import DashboardsDefault from 'views/trader/dashboard';
import TradesDefault from 'views/trader/trades';
import AccountsDefault from 'views/trader/accounts';
import JournalDefault from 'views/trader/journal';
import StrategiesDefault from 'views/trader/strategies';
import MentorDefault from 'views/trader/mentor';
import SubscribeDefault from 'views/trader/subscription';
import AdminDefault from 'views/trader/admin';


// Landing Imports
import PricingDefault from 'views/landing/pricing';
import LandingDefault from 'views/landing/home';
import FeaturesDefault from 'views/landing/features';
import Terms from 'views/landing/legal/Terms';
import Privacy from 'views/landing/legal/Privacy';


// Auth Imports
import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCentered';
import SignInCentered from 'views/auth/signIn/SignInCentered';
import SignUpCentered from 'views/auth/signUp/SignUpCentered';
import VerificationSignup from 'views/auth/verification/VerificationSignup';
import PasswordReset from 'views/auth/forgotPassword/PasswordReset';
import { RiAdminLine } from 'react-icons/ri';


const routes = [
  // --- Dashboards ---
  {
    name: 'Dashboard',
    layout: '/trader',
    path: '/dashboard',
    icon: <Icon as={MdOutlineDashboardCustomize} width="20px" height="20px" color="inherit" />,
    component: <DashboardsDefault />,
  },
  {
    name: 'Trades',
    layout: '/trader',
    path: '/trades',
    icon: <Icon as={MdInsights} width="20px" height="20px" color="inherit" />,
    component: <TradesDefault />,
  },
  {
    name: 'Accounts',
    layout: '/trader',
    path: '/accounts',
    icon: <Icon as={TbUserBolt} width="20px" height="20px" color="inherit" />,
    component: <AccountsDefault />,
  }
  ,
  {
    name: 'Strategies',
    layout: '/trader',
    path: '/strategies',
    icon: <Icon as={BsClipboardData} width="20px" height="20px" color="inherit" />,
    component: <StrategiesDefault />,
  },
  {
    name: 'Journal',
    layout: '/trader',
    path: '/journal',
    icon: <Icon as={BsJournalBookmark} width="20px" height="20px" color="inherit" />,
    component: <JournalDefault />,
  },
  {
    name: 'Mentorship',
    layout: '/trader',
    path: '/mentorship',
    icon: <Icon as={TbUsers} width="20px" height="20px" color="inherit" />,
    component: <MentorDefault />,
  },
  {
    name: 'Subscription',
    layout: '/trader',
    path: '/subscribe',
    icon: <Icon as={TbUserCheck} width="20px" height="20px" color="inherit" />,
    component: <SubscribeDefault />,
  },
  {
    name: 'Admin',
    layout: '/trader',
    path: '/admin',
    icon: <Icon as={RiAdminLine} width="20px" height="20px" color="inherit" />,
    component: <AdminDefault />,
  },

  
  // --- Authentication ---
  {
    name: 'Authentication',
    path: '/auth',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Signin',
        layout: '/auth',
        path: '/signin',
        component: <SignInCentered />,
      },
      {
        name: 'Signup',
        layout: '/auth',
        path: '/signup',
        component: <SignUpCentered />,
      },
      {
        name: 'Verification',
        layout: '/auth',
        path: '/verify',
        component: <VerificationSignup />,
      },
      {
        name: 'Forgot Password',
        layout: '/auth',
        path: '/forgotpassword',
        component: <ForgotPasswordCentered />,
      },
      {
        name: 'Change Password',
        layout: '/auth',
        path: '/passwordreset',
        component: <PasswordReset />,
      }
    ],
  },
  // --- Landing/Public ---
  {
    name: 'Landing',
    path: '/home',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Home',
        layout: '/auth',
        path: '/',
        component: <LandingDefault />,
      },
      {
        name: 'Pricing',
        layout: '/auth',
        path: '/pricing',
        component: <PricingDefault />,
      },
      {
        name: 'Features',
        layout: '/auth',
        path: '/features',
        component: <FeaturesDefault />,
      },
      {
        name: 'Terms',
        layout: '/auth',
        path: '/terms',
        component: <Terms />,
      },
      {
        name: 'Privacy',
        layout: '/auth',
        path: '/privacy',
        component: <Privacy />,
      },
    ],
  },

];

export default routes;
