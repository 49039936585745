import { Flex, Select, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { ChangeEvent, useEffect, useState } from 'react'
import { useData } from 'services/data/DataProvider'
import IntradayChart from 'views/trader/dashboard/components/charts/IntradayChart'
import MonthlyChart from 'views/trader/dashboard/components/charts/MonthlyChart'
import WeekdayChart from 'views/trader/dashboard/components/charts/WeekdayChart'
import { TimeSeriesData } from 'views/trader/dashboard/variables/TimeSeriesData'
import { StatIntraday, StatMonthly, StatWeekday } from 'views/trader/trades/variables/types'

const TimeCharts = (props: { hidden?: boolean }) => {

    const { periodtrades, defaultcurrency } = useData();

    const [intraday, setIntraday] = useState<StatIntraday[]>([])
    const [weekdays, setWeekdays] = useState<StatWeekday[]>([])
    const [monthly, setMonthly] = useState<StatMonthly[]>([])
    const [ticker, setTicker] = useState("");
    const [tickers, setTickers] = useState([]);
    const [hidden, setHidden] = useState(false);
    const [defaultTab, setDefaultTab] = useState(localStorage.getItem('tchartdefault') ? JSON.parse(localStorage.getItem('tchartdefault')) : 0);

    useEffect(() => {
        updateVariables();
    }, [periodtrades])

    const updateVariables = async () => {
        setHidden(!periodtrades.length);
        if (periodtrades.length) {
            await TimeSeriesData(periodtrades, defaultcurrency).then((res) => {
                setTickers(res.tickers);
                setIntraday(res.day);
                setWeekdays(res.week);
                setMonthly(res.month);
                setHidden(false);
            })
        }
    }
    const changeTabDefault = (e: number) => {
        localStorage.setItem('tchartdefault', JSON.stringify(e));
        setDefaultTab(e);
        //alert(JSON.stringify(e))
    }

    const changeChart = (e: ChangeEvent<HTMLSelectElement>) => {
        setTicker(e.target.value);
        //localStorage.setItem("chart", e.target.value);
    }

    return (
        <Flex hidden={props.hidden} direction={'column'}>
            <Flex justify={'start'}>
                <Select size={'sm'} w='150px' borderRadius={'5px'} placeholder={'All tickers'} onChange={changeChart}>
                    {tickers.map(t => (
                        <option key={t} value={t} >
                            {t}
                        </option>
                    ))}
                </Select>
            </Flex>

            <Tabs isFitted variant='enclosed' mt="10px" align='center' defaultIndex={defaultTab} onChange={changeTabDefault}>
                <TabList>
                    <Tab>Intraday</Tab>
                    <Tab>Weekdays</Tab>
                    <Tab>Monthly</Tab>
                </TabList>
                <TabPanels borderWidth={'1px'} borderBottomRadius={'20px'}>
                    <TabPanel>
                        <IntradayChart stats={intraday} currency={defaultcurrency} ticker={ticker} hidden={hidden} />
                    </TabPanel>
                    <TabPanel>
                        <WeekdayChart stats={weekdays} currency={defaultcurrency} ticker={ticker} hidden={hidden} />
                    </TabPanel>
                    <TabPanel>
                        <MonthlyChart stats={monthly} currency={defaultcurrency} ticker={ticker} hidden={hidden} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Flex>
    )
}

export default TimeCharts