import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Icon, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { MdFullscreen } from 'react-icons/md'
import PeriodCharts from '../stats/PeriodCharts'
import Card from 'components/card/Card'
import ReactDOM from 'react-dom'

const PeriodModal = (props: { hidden: boolean }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef()
    const drawerBg = useColorModeValue('white', 'navy.800');

    return (
        <>
            <Button variant='outline' onClick={onOpen} borderRadius={'5px'} size={'sm'} ms='5px' hidden={props.hidden}>
                <Icon as={MdFullscreen} boxSize={5} />
            </Button>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
                size={'full'}
            >
                <DrawerOverlay />
                <DrawerContent bg={drawerBg}>
                    <DrawerCloseButton />
                    <DrawerHeader>Period PL</DrawerHeader>
                    <DrawerBody>
                            <PeriodCharts hidden={false} minHeight={'800px'} />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default PeriodModal