export const getDuration = (milliDiff: number) => {

    const totalSeconds = Math.floor(milliDiff / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);

    const totalDays = Math.floor(totalHours / 24);
    const remSeconds = totalSeconds % 60;
    const remMinutes = totalMinutes % 60;
    const remHours = totalHours % 24;

    let days = totalDays > 0 ? totalDays + "d " : "";
    let hours = remHours > 0 ? remHours + "h " : "";
    let mins = remMinutes > 0 ? remMinutes + "m " : "";
    let secs = remSeconds > 0 ? remSeconds + "s " : "";

    return (milliDiff < 1000 ? "" : days + hours + mins + secs);
}

export const currencyformat = (val: number, currcode: string) => {
    let curr = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: (currcode ? currcode : 'USD'),
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    return curr.format(Number(val));
}

export const scoreColour = (val: any) => {
    if (val < 26) { return "red"; }
    else if (val < 51) { return "orange"; }
    else if (val < 76) { return "teal"; }
    else { return "blue"; }
}

export const numberColor = (val: any) => {
    return val < 0 ? "red.500" : (val > 0 ? "green.500" : "gray.400");
}

export async function formatPrice(ticker: string, price: string, delimiter: string): Promise<number> {

    return new Promise(async (resolve, reject) => {

        let p = 0;
        let arr;

        switch (ticker) {
            case 'ZN': // 1/2 of 1/32 eg 108'30'5
                arr = price.split(delimiter);
                p = Number(arr[0]) + (arr.length > 1 ? (Number(arr[1]) > 0 ? Number(arr[1]) / 32 : 0) + (Number(arr[2]) > 0 ? Number(arr[2]) / 64 : 0) : 0);
                break;
            case 'ZF': // 1/4 of 1/32 eg 108'30'6
                arr = price.split(delimiter);
                p = Number(arr[0]) + (arr.length > 1 ? (Number(arr[1]) > 0 ? Number(arr[1]) / 32 : 0) + (Number(arr[2]) > 0 ? Number(arr[2]) / 128 : 0) : 0);
                break;
            case 'ZT': // 1/8 of 1/32 eg 103'12'7
                arr = price.split(delimiter);
                p = Number(arr[0]) + (arr.length > 1 ? (Number(arr[1]) > 0 ? Number(arr[1]) / 32 : 0) + (Number(arr[2]) > 0 ? Number(arr[2]) / 256 : 0) : 0);
                break;
            case 'ZB':
            case 'UB': // 1/32 eg 130'20
                arr = price.split(delimiter);
                p = Number(arr[0]) + (arr.length > 1 ? (Number(arr[1]) > 0 ? Number(arr[1]) / 32 : 0) : 0);
                break;
            case 'ZS':
            case 'ZC':
            case 'ZW': //1/4 of 1c (8ths) eg 1520'6
                arr = price.split(delimiter);
                p = Number(arr[0]) + (arr.length > 1 ? (Number(arr[1]) > 0 ? Number(arr[1]) / 8 : 0) : 0);
                break;
            default: p = Number(price);
        }

        resolve(p);
    });
}


