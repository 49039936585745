import { Trade } from "views/trader/trades/variables/types";
import api from "./api";
import { setDefaultGroup, setGroupsSyncDue } from "./dbservice";

export async function updateTrade(trade: Trade): Promise<any> {
    return new Promise(async (resolve, reject) => {
        await api.post('/trade/updatetrade', trade)
            .then(async (res) => {
                resolve(res.data);
            }).catch(async (err) => {
                resolve(true); // ie sync_due = true;
            })
    })
}

export async function updatesubscription(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        await api.post("/subs/update", payload)
            .then(async (res) => {
                resolve(res.data);
            }).catch(async (err) => {
                resolve(false);
            });
    })
}

export async function updateDefaultGroup(grpid: number, userid: number, muuid: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
        await setDefaultGroup(grpid, userid).then(async () => {
            await api.post('/accounts/defaultgroup', { groupid: grpid, uuid: muuid })
                .then(async (res) => {
                    if (res === undefined) {
                        await setGroupsSyncDue(true);
                    }
                })
                .catch(async (error) => {
                    await setGroupsSyncDue(true);
                });
        }).catch(() => { });

        resolve('');
    })
}


