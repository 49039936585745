import { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Icon, useColorModeValue } from '@chakra-ui/react'
import MentorHelp from './MentorHelp'
import { MdHelpCenter, MdOutlineHelp } from 'react-icons/md'
import { IoHelpCircleOutline } from 'react-icons/io5'

const MentorHelpModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const bgcolor = useColorModeValue('white', 'navy.900');
    return (
        <>
            <Button variant={'solid'} borderWidth={'1px'} bg={bgcolor} onClick={onOpen} zIndex={10} ms='5px'>
                <Icon as={IoHelpCircleOutline} boxSize={6} />
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
                <ModalOverlay />
                <ModalContent bg={bgcolor}>
                    <ModalHeader>Mentor help</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <MentorHelp />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} mt={3} onClick={onClose}>
                            Got it!
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default MentorHelpModal