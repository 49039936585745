import dayjs from "dayjs";
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { PeriodChartData, Trade } from "views/trader/trades/variables/types";

export async function PeriodSeriesData(periodtrades: Trade[], currency: string): Promise<PeriodChartData> {

    dayjs.extend(weekOfYear);

    return new Promise(async (resolve, reject) => {

        let trades: any[] = [];
        let daily: any[] = [];
        let weekly: any[] = [];

        let day = (periodtrades.length ? dayjs(periodtrades[0].datetime) : dayjs());
        let week = (periodtrades.length ? dayjs(periodtrades[0].datetime).startOf('week').day(1) : dayjs());

        let daytotal = 0;
        let weektotal = 0;

        let count = 1;

        for (let i = 0; i < periodtrades.length; i++) {

            let tr: Trade = periodtrades[i];

            let ex = (tr.currency !== currency ? tr.exchange : 1);
            let nett = Number((tr.nett / ex).toFixed(2));

            trades.push({ label: count + ': ' + dayjs(tr.datetime).format("YYYY-MM-DD"), nett: nett })
            count++;

            ///////////////
            if (day.isSame(tr.datetime, 'day')) {
                daytotal += nett;
            }
            else {
                daily.push({ start: day.format("YYYY-MM-DD"), nett: daytotal })
                day = dayjs(tr.datetime);
                daytotal = nett;
            }
            if (i === periodtrades.length - 1) {
                daily.push({ start: day.format("YYYY-MM-DD"), nett: daytotal })
            }
            ///////////////

            if (week.isSame(tr.datetime, 'week')) {
                weektotal += nett;
            }
            else {
                weekly.push({ start: "Week " + week.week() + " : " + week.format("YYYY-MM-DD"), nett: weektotal })
                week = dayjs(tr.datetime).startOf('week').day(1);
                weektotal = nett;
            }
            if (i === periodtrades.length - 1) {
                weekly.push({ start: "Week " + week.week() + " : " + week.format("YYYY-MM-DD"), nett: weektotal })
            }
            ///////////////
        }

        let data: PeriodChartData = {
            trades: trades,
            daily: daily,
            weekly: weekly
        }

        resolve(data);
    })
}